import React from 'react';
import { createRoot } from 'react-dom/client';
import { v4 as uuidv4 } from 'uuid';
import DialogList, { DialogArgs } from 'app/blocks/dialog/dialog-list';

const DIALOG_MODAL_CONTAINER_ID = 'dialog-modal';

type State = {
    dialogs: Array<{ key: string; args: DialogArgs }>;
    showExtendedMessage: boolean;
};

class DialogController extends React.Component<{}, State> {
    state: State = {
        dialogs: [],
        showExtendedMessage: false,
    };

    componentDidUpdate() {
        const cls = document.querySelector('html').classList;

        if (this.state.dialogs.length > 0) {
            cls.add('modal-opened');
        } else {
            cls.remove('modal-opened');
        }
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    openDialog = getArgsHandler => {
        const dialogKey = uuidv4();

        const closeDialogAnd = handler => () => {
            if (handler) {
                handler();
            }

            this.setState(({ dialogs }) => ({ dialogs: dialogs.filter(d => d.key !== dialogKey) }));
        };

        const dialogProps = {
            args: getArgsHandler(closeDialogAnd),
            key: dialogKey,
        };

        this.setState(({ dialogs }) => ({ dialogs: [...dialogs, dialogProps] }));
    };

    toggleExtendedMessage = () => {
        this.setState(state => ({ showExtendedMessage: !state.showExtendedMessage }));
    };

    render() {
        return (
            <DialogList
                dialogs={this.state.dialogs}
                showExtendedMessage={this.state.showExtendedMessage}
                toggleExtendedMessage={this.toggleExtendedMessage}
            />
        );
    }
}

// Controller instance (singleton)
let controller;
let queue = []; // dialogs which happens before controller mounts
let root;

function mountController() {
    return new Promise(resolve => {
        let node = document.getElementById(DIALOG_MODAL_CONTAINER_ID);
        if (!node) {
            node = document.createElement('div');
            node.id = DIALOG_MODAL_CONTAINER_ID;
            document.body.appendChild(node);
        }

        root = root || createRoot(node);
        root.render(
            <DialogController
                ref={c => {
                    controller = c;

                    if (controller) {
                        queue.forEach(w => controller.openDialog(w));
                        queue = [];
                        resolve(true);
                    }
                }}
            />,
        );
    });
}

export default function (getDialogArgs: (close: (fn?: () => void) => () => void) => DialogArgs): void {
    if (controller) {
        controller.openDialog(getDialogArgs);
    } else {
        queue.push(getDialogArgs);

        if (controller === undefined) {
            mountController();
        }
    }
}

// for testing purposes
export async function getController() {
    if (controller) {
        try {
            if (root) {
                root.render(null);
            }
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }

    await mountController();

    // fixme: it could be time gap  between mounting and ref appears
    return controller;
}
