import cn from 'classnames';
import React from 'react';

type Props = {
    className?: string;
    isError?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeValue?: (value: boolean) => void;
    style?: Record<string, string | number>;
    [prop: string]: any;
};

export default React.forwardRef<HTMLInputElement, Props>(
    ({ className, isError, onChange, onChangeValue, style, ...props }, ref) => (
        <label className={cn(className, 'Checkbox', { 'Checkbox--error': isError })} style={style}>
            <input
                {...props}
                ref={ref}
                onChange={event => {
                    if (onChange) {
                        onChange(event);
                    }

                    if (onChangeValue) {
                        onChangeValue(event.target.checked);
                    }
                }}
                type="checkbox"
            />
            <span className="FormControlIcon">
                <svg
                    focusable="false"
                    shapeRendering="geometricPrecision"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect height="20" width="20" />
                    <rect fill="#ffffff" height="18" width="18" x="1" y="1" />
                    <rect className="HoverIndicator" height="2" width="18" x="1" y="1" />
                    <rect className="HoverIndicator" height="16" width="2" x="1" y="3" />
                    <rect className="CheckedIndicator" fill="#1C1D1E" height="12" width="12" x="4" y="4" />
                </svg>
            </span>
        </label>
    ),
);
