import { sha256 } from 'js-sha256';
import { compactObject } from 'app/blocks/common/utils';
import { getUser } from 'app/blocks/middleware/authentication';

function getConsentOsanoString(consent, fields = []) {
    return fields
        .map(f => (consent[f] ? `${f.toLowerCase()} : ${consent[f]}` : undefined))
        .filter(v => !!v)
        .join(' | ')
        .toLowerCase();
}

function getConsentOsano() {
    if (window.Osano) {
        const { cm } = window.Osano;
        if (cm && cm.getConsent) {
            const consent = cm.getConsent();
            return getConsentOsanoString(consent, ['ANALYTICS', 'MARKETING', 'PERSONALIZATION', 'ESSENTIAL']);
        }
    }
    return undefined;
}

const mapUserToAnalyticUserData = ({ sendEmailFlag, participantId, email, userId: ecid, loggedIn, orcidId }) => {
    return {
        ...compactObject({
            'consent-osano': getConsentOsano(),
            'login-status': loggedIn ? 'logged-in' : 'guest',
            'encrypted-email-id': email ? sha256(email) : '',
            'participant-uuid': participantId,
            'alm-internal-id': ecid,
            orcid: orcidId,
        }),
        'consent-marketing': sendEmailFlag,
    };
};

const generateUser = async () => {
    const user = getUser();
    return user.loggedIn
        ? mapUserToAnalyticUserData(user)
        : {
              'consent-osano': getConsentOsano(),
              'login-status': 'guest',
          };
};

export default generateUser;

export { getConsentOsano, mapUserToAnalyticUserData };
