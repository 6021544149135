import React from 'react';

type Props = Either<{ html: string; innerContent: React.ReactNode }> & { type: string };

export default function DialogMessage({ html, innerContent, type }: Props) {
    if (html) {
        return (
            <div
                className="DialogMessage"
                dangerouslySetInnerHTML={{ __html: html }}
                data-seleniumid={`${type}-message`}
            />
        );
    }

    return (
        <div className="DialogMessage" data-seleniumid={`${type}-message`}>
            {innerContent}
        </div>
    );
}
