import axios, { AxiosRequestHeaders } from 'axios';

const headersInjection = {};

function mockAddPermanentRequestHeader(key, value) {
    if (value == null) {
        delete headersInjection[key];
    } else {
        headersInjection[key] = value;
    }
    return headersInjection;
}

function mockGetPermanentRequestHeaders() {
    return headersInjection;
}

function enableAjaxInjection() {
    axios.interceptors.request.use(config => ({
        ...config,
        headers: { ...config.headers, ...headersInjection } as AxiosRequestHeaders,
    }));

    window.mockAddPermanentRequestHeader = mockAddPermanentRequestHeader;
    window.mockGetPermanentRequestHeaders = mockGetPermanentRequestHeaders;

    return true;
}

export default enableAjaxInjection;
