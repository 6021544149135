import cn from 'classnames';
import React, { ReactNode } from 'react';
import { Button, LinkButton, PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import Modal from 'app/blocks/Modal/Modal';
import ExtendedMessage from './dialog__extended-message';
import DialogMessage from './dialog__message';
import './dialog.scss';

export type DialogArgs = {
    cancelBtnLabel?: string;
    CancelComponent?: React.ComponentType<{
        className?: string;
        ['data-seleniumid']?: string;
        onClick?: () => void;
        children: ReactNode;
    }>;
    className?: string;
    closeBtnLabel?: string;
    confirmBtnLabel?: string;
    ConfirmComponent?: React.ComponentType<{
        className?: string;
        ['data-seleniumid']?: string;
        onClick?: () => void;
        children: ReactNode;
    }>;
    extendedMessage?: React.ReactNode;
    html?: string;
    innerContent?: React.ReactNode;
    message?: string;
    text?: string;
    type: 'confirmation' | 'error' | 'info' | 'success' | React.ComponentType<any>;
    onApprove?: () => void;
    onClose?: () => void;
    onIgnoreButtons?: () => void;
    onReject?: () => void;
    refId?: string;
    seleniumid?: string;
};

type DialogProps = DialogArgs & {
    l: l;
    showExtendedMessage: boolean;
    toggleExtendedMessage: () => void;
};

function isCustomDialog(
    type: 'confirmation' | 'error' | 'info' | 'success' | React.ComponentType,
): type is React.ComponentType<any> {
    return typeof type === 'function';
}

export function Dialog({ l, showExtendedMessage, toggleExtendedMessage, type, ...props }: DialogProps) {
    if (isCustomDialog(type)) {
        const CustomDialog = type;
        return <CustomDialog {...props} />;
    }

    const {
        CancelComponent,
        ConfirmComponent,
        cancelBtnLabel,
        className,
        closeBtnLabel,
        confirmBtnLabel,
        extendedMessage,
        html,
        innerContent,
        message,
        onApprove,
        onClose,
        onIgnoreButtons,
        onReject,
        refId,
        seleniumid,
        text,
    } = props;

    let modal = null;
    const classNameDialog = 'Dialog';

    // @ts-ignore
    const dialogMessage = <DialogMessage html={html || message} innerContent={innerContent || text} type={type} />;
    const seleniumIdPrefix = seleniumid || type;

    const extended = extendedMessage && (
        <ExtendedMessage toggle={toggleExtendedMessage} visible={showExtendedMessage}>
            {extendedMessage}
        </ExtendedMessage>
    );

    switch (type) {
        case 'confirmation': {
            modal = (
                <Modal
                    className={classNameDialog}
                    ignoreBackdropClicks
                    onClose={onReject}
                    onIgnoreButtons={onIgnoreButtons}
                    type={type}
                >
                    <div className={`${classNameDialog}-Body modal-body`} data-seleniumid="modal-body">
                        {dialogMessage}
                        {extended}
                        <div className={`${classNameDialog}-BodyActions ${classNameDialog}-BodyActions-Column`}>
                            <ConfirmComponent
                                className={cn(`${classNameDialog}-Confirm`, {
                                    noStretch: CancelComponent === LinkButton,
                                })}
                                data-seleniumid={`${seleniumIdPrefix}-dialog-confirm-button`}
                                onClick={onApprove}
                            >
                                {confirmBtnLabel || l('DIALOG.CONFIRM')}
                            </ConfirmComponent>
                            <CancelComponent
                                className={`${classNameDialog}-Cancel`}
                                data-seleniumid={`${seleniumIdPrefix}-dialog-cancel-button`}
                                onClick={onReject}
                            >
                                {cancelBtnLabel || l('DIALOG.CANCEL')}
                            </CancelComponent>
                        </div>
                    </div>
                </Modal>
            );
            break;
        }

        case 'error':
            modal = (
                <Modal className={classNameDialog} onClose={onClose} onIgnoreButtons={onIgnoreButtons} type={type}>
                    <div
                        className={`${classNameDialog}-Body modal-body id-error-dialog-modal-body`}
                        data-seleniumid="modal-body"
                    >
                        {dialogMessage}
                        {refId && (
                            <div className={`${classNameDialog}-RefId`} data-seleniumid="dialog-refId">
                                {`refId: ${refId}`}
                            </div>
                        )}
                        {extended}
                        <div className={`${classNameDialog}-BodyActions`}>
                            <Button
                                className={`${classNameDialog}-Cancel id-error-dialog-close-button`}
                                data-seleniumid={`${seleniumIdPrefix}-dialog-close-button`}
                                onClick={onClose}
                            >
                                {closeBtnLabel || l('DIALOG.CLOSE')}
                            </Button>
                        </div>
                    </div>
                </Modal>
            );
            break;

        case 'info':
        case 'success':
            modal = (
                <Modal className={classNameDialog} onClose={onClose} onIgnoreButtons={onIgnoreButtons} type={type}>
                    <div
                        className={`${classNameDialog}-Body modal-body id-error-info-modal-body`}
                        data-seleniumid="modal-body"
                    >
                        {dialogMessage}
                        {extended}
                        <div className={`${classNameDialog}-BodyActions`}>
                            <Button
                                className={`${classNameDialog}-Cancel`}
                                data-seleniumid={`${seleniumIdPrefix}-dialog-close-button`}
                                onClick={onClose}
                            >
                                {closeBtnLabel || l('DIALOG.CLOSE')}
                            </Button>
                        </div>
                    </div>
                </Modal>
            );
            break;
        default:
            throw new Error('Unknown modal type');
    }

    return <div className={className}>{modal}</div>;
}

Dialog.defaultProps = {
    CancelComponent: Button,
    ConfirmComponent: PrimaryButton,
};

type DialogListProps = {
    dialogs?: Array<{ key: string; args: DialogArgs }>;
    l: l;
    showExtendedMessage: boolean;
    toggleExtendedMessage: () => void;
};

function DialogList({ dialogs = [], l, showExtendedMessage, toggleExtendedMessage }: DialogListProps) {
    return (
        <div>
            {dialogs.map(x => (
                <Dialog
                    {...x.args}
                    key={x.key}
                    l={l}
                    showExtendedMessage={showExtendedMessage}
                    toggleExtendedMessage={toggleExtendedMessage}
                />
            ))}
        </div>
    );
}

export default withCodes(DialogList, ID.DIALOG);
