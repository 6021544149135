import showDialog from 'app/blocks/common/jsx/dialogModal';
import { showOutdatedDashboardModal } from 'app/blocks/Modal/OutdatedDashboardModal';
import routes from 'app/pages/routes';

const defaultErrorHandler = function (error) {
    if (error.code === 'POSSIBLE_OUTDATED_DATA') {
        showOutdatedDashboardModal();
    } else {
        showDialog.error(error, { onClose: routes.navigateToDashboard });
    }
};

function decorate(errorHandler, target, key, descriptor) {
    function wrapper(fn) {
        return async function (...args) {
            try {
                return await fn.call(this, ...args);
            } catch (error) {
                console.error(error);
                return errorHandler.call(this, error);
            }
        };
    }

    if (!descriptor) {
        // descriptor is not passed in ts code
        const fn = target[key];

        Object.defineProperty(target, key, { value: wrapper(fn) });

        return undefined;
    }

    const fn = descriptor.value;

    return {
        ...descriptor,
        value: wrapper(fn),
    };
}

function tryCatch(...args) {
    if (
        args.length === 1 &&
        typeof args[0] === 'object' &&
        'errorHandler' in args[0] &&
        typeof args[0].errorHandler === 'function'
    ) {
        return function (...args2) {
            return decorate(args[0].errorHandler, ...args2);
        };
    }

    return decorate(defaultErrorHandler, ...args);
}

tryCatch.popUpOnly = tryCatch({
    errorHandler: error => {
        if (error.code === 'POSSIBLE_OUTDATED_DATA') {
            showOutdatedDashboardModal();
        } else {
            showDialog.error(error);
        }
    },
});

tryCatch.showPopUpAndGoToDashboard = tryCatch({ errorHandler: defaultErrorHandler });

export default tryCatch;
