import cn from 'classnames';
import React from 'react';
import WileyCombobox from 'app/ui/as-ui-components/combobox';

// TODO move ComboboxProps into @wiley/as-ui-components
type ComboboxProps<Item extends string | Object> = {
    /** id for selenium tests */
    seleniumid: string;

    /** Function for fetch data */
    getListDataPromise: (search: string, pageSize: number, offset: number) => Promise<Item[]>;
    /** External handler for selecting an item */
    changeHandler: (item: Item | null) => void;

    /** Type of entered value */
    customFeatureName?: string;
    /** Display or no type of entered value */
    displayUseCustomFeature?: boolean;
    /** Time delay before fetch items list */
    throttleWaitFetch?: number;

    /** Function for render items of list */
    renderListItem?: (item: Item, index: number, inputValue: string) => React.ReactNode;
    /** Mapper for entered value */
    enteredValueMapper?: (input: string) => string;
    /** Compare input value with ... */
    equalPredicate?: (search: string, item: Item) => string;
    /** External handler for changing state of combobox - open/close */
    toggleIsOpen?: () => void;
    onEnter?: () => void;
    onFocus?: (e: FocusEvent) => void;
    onBlur?: (e: FocusEvent) => void;
    onBeforeKeyDown?: (e: KeyboardEvent) => void;

    /** Object with CSS classNames */
    className?: string;
    comboboxClassNames?: {
        select?: string;
        overlay?: string;
        options?: string;
        loading?: string;
        input?: string;
    };
    /** The property values of the items in the list that will be displayed */
    displayingProp?: string;
    /** id of object nullItem */
    nullItemId?: string;
    /** Value for nullItem[displayingProp] and undefinedItem[displayingProp] */
    nullItemName?: string;
    /** id of selected item or selected item object */
    selectedItem?: Item;
    /** Size of items list */
    pageSize?: number;
    /** Min length of input value */
    requestMinLength?: number;
    isOpen?: boolean;
    disabled?: boolean;
    placeholder?: string;
    isDisplayPlaceholder?: boolean;
    hideArrow?: boolean;
    /** Using or no none value */
    hideNoneValue?: boolean;
    emptyValueOnSelect?: boolean;
    notRestorePrevious?: boolean;
    /** Open/close combobox when input corrected or incorrected search value */
    toggleOnChange?: boolean;
    /** Disabled fetch next data */
    noRequestNextPage?: boolean;
    /** Auto fetch next data when items list is scrolled to end */
    endlessScroll?: boolean;

    /** ??? */
    options?: React.ReactNode;
    error?: React.ReactNode;

    comboboxInstance?: (combobox: React.ReactElement) => void;
};

export type Props<T> = { isError?: boolean } & ComboboxProps<T>;

const Combobox = <T extends string | Object = any>({ className, isError, ...props }: Props<T>) => (
    <WileyCombobox {...props} className={cn('form-control', className, { error: isError })} />
);

export default Combobox;
