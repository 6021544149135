import React from 'react';

// only simple types (string/number/bool) are supported
export default function (...propsToCheck) {
    return function keyPropsDecorator(ClassOrFunction) {
        return React.forwardRef(function ClassOrFunctionWithKeyProps(props, ref) {
            return <ClassOrFunction key={propsToCheck.map(p => props[p]).join(',')} ref={ref} {...props} />;
        });
    };
}
