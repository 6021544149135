import React from 'react';
import middleware from 'app/blocks/middleware/static';
import StaticContent from 'app/blocks/static-content/static-content';

import './resources.scss';

const TABS = ['menu_resources__authors.html', 'menu_resources__reviewers.html', 'menu_resources__other.html'];

// preloading
TABS.forEach(src => middleware.getHtml(src));

function Resources(): React.ReactElement {
    return (
        <div className="menu-resources-dropdown">
            {TABS.map(src => (
                <div className="menu-resources-column">
                    <StaticContent hideLoadingText src={src} />
                </div>
            ))}
        </div>
    );
}

export default Resources;
