const getErrorMessage = (validateResult, prefix, postfix) =>
    Object.values(validateResult)
        .filter(item => item.length)
        .map(item => item.split('.').pop().replace(prefix, '').replace(postfix, ''))
        .join(' | ');

const getErrorObject = error => (error ? { error: { code: error.code, message: error.message } } : undefined);

export default getErrorMessage;
export { getErrorObject };
