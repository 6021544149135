import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { addLoginValue, addRegistrationValue } from 'app/blocks/analytics/storage';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes, lAsync } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import withResponsive from 'app/blocks/common/responsive-decorator';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import * as Utils from 'app/blocks/common/utils';
import { getQueryParamSafe } from 'app/blocks/common/utils';
import withAuth from 'app/blocks/common/withAuth';
import { confirmLogout } from 'app/blocks/common/withConfirmLeaving';
import { logout, authenticateASLogin, getUiConnectUrl, securityKey } from 'app/blocks/middleware/authentication';
import { getUrlParam } from 'app/blocks/middleware/url-params';
import routes from 'app/pages/routes.json';
import { convertToHashUrl, navigate, isOnlyMenu } from 'app/route/history';
import { DropdownContainer } from 'app/ui/dropdown';
import Dropdown from 'app/ui/dropdown/Dropdown';
import MenuSearch from './__search/menu__search';
import ResourcesMenu from './resources';

import './menu.scss';
import './menu__avatar.scss';

function LinkButtonOrLink({ className = '', href, ...props }) {
    return isOnlyMenu() ? (
        <LinkButton className={className} href={convertToHashUrl(href)} {...props} isLinkTag />
    ) : (
        <Link className={cn('btn btn-link', className)} to={href} {...props} />
    );
}

type State = {
    isUserNameOpen: boolean;
    isResourcesOpen: boolean;
};

class Menu extends React.Component<
    {
        isAuth: boolean;
        isMobile: boolean;
        isTablet: boolean;
        l: l;
        onDisplay?: () => void;
        user?: User;
    },
    State
> {
    static defaultProps = {
        onDisplay: () => {},
    };

    state: State = {
        isResourcesOpen: false,
        isUserNameOpen: false,
    };

    async componentDidMount() {
        this.props.onDisplay();
        const ranStr = localStorage.getItem('STATE_ID');
        const queryParams = getQueryParamSafe(window.location.href);
        if (queryParams?.code && queryParams?.id_token) {
            queryParams.idToken = queryParams.id_token;
            delete queryParams.id_token;

            if (true || ranStr === queryParams?.state) {
                this.authenticateUser(queryParams);
            } else {
                setTimeout(() => {
                    navigate('errorRedirectHome');
                }, 2000);
            }
        }
        if (queryParams?.error === 'consent_required') {
            const message = await lAsync('ERROR.CONNECT_TERMSOFSERVICE_ERROR');
            showDialog.info({ message });
        }
    }

    timeoutPromise = () =>
        new Promise((resolve, reject) => {
            setTimeout(() => {
                reject(new Error('Timeout occurred'));
            }, parseInt(this.props.l(`${ID.BUTTONS}.AUTH_TIMEOUT`), 10));
        });

    authenticateUser = async queryParams => {
        try {
            await Promise.race([authenticateASLogin(queryParams), this.timeoutPromise()]);
            setTimeout(() => {
                navigate('dashboard');
            }, 2000);
        } catch (err) {
            setTimeout(() => {
                navigate('errorRedirectHome');
            }, 2000);
        }
    };

    onLogout = async () => {
        if (await confirmLogout()) {
            logout();
        }
    };

    hideMenu = () => {
        this.setState({ isResourcesOpen: false, isUserNameOpen: false });
    };

    goToLogin = async () => {
        addLoginValue('page-header');
        const returnUrl = getUrlParam('returnUrl');
        if (returnUrl) {
            navigate('login');
        } else {
            const url = await getUiConnectUrl();
            const securityUrl = securityKey(url);
            Utils.windowLocationReplace(securityUrl);
        }
    };

    goToRegistration = async () => {
        addRegistrationValue('menu-registration-btn');
        const returnUrl = getUrlParam('returnUrl');
        if (returnUrl) {
            navigate('register');
        } else {
            const url = await getUiConnectUrl();
            const securityUrl = securityKey(url);
            Utils.windowLocationReplace(`${securityUrl}&login_hint&action=register`);
        }
    };

    render() {
        const { isAuth, isMobile, isTablet, l, user } = this.props;
        const { isResourcesOpen, isUserNameOpen } = this.state;
        const returnUrl = getUrlParam('returnUrl');
        const register_token = window.location.href.match(/#\/?register-invite/);
        return register_token ? (
            <div />
        ) : (
            <div className="menu__wrapper">
                <div className="container menu-row">
                    <div className="menu-logo" data-seleniumid="menu-logo">
                        <a
                            className="link"
                            data-seleniumid="menu-logo-link"
                            href={l(`${ID.BUTTONS}.TOP_MENU.LOGO_URL`)}
                        >
                            {returnUrl ? l(`${ID.BUTTONS}.TOP_MENU.FDLOGO`) : l(`${ID.BUTTONS}.TOP_MENU.LOGO`)}
                        </a>
                    </div>

                    <div className="menu-items" data-seleniumid="menu-items">
                        <LinkButtonOrLink data-seleniumid="menu-dashboard" href={routes.dashboard}>
                            {l(`${ID.BUTTONS}.TOP_MENU.DASHBOARD`)}
                        </LinkButtonOrLink>
                        <LinkButton
                            data-seleniumid="menu-find-a-journal"
                            href={l(`${ID.BUTTONS}.TOP_MENU.FIND_URL`)}
                            isLinkTag
                        >
                            {l(`${ID.BUTTONS}.TOP_MENU.FIND`)}
                        </LinkButton>
                        <DropdownContainer className="ResourcesMenu">
                            <LinkButton
                                data-seleniumid="menu-resources"
                                onClick={() =>
                                    this.setState({ isResourcesOpen: !isResourcesOpen, isUserNameOpen: false })
                                }
                            >
                                {l(`${ID.BUTTONS}.TOP_MENU.RESOURCES`)}
                                <SvgIcon.chevron className="menu-icon" iconDescr="Arrow down" iconTitle="resources" />
                            </LinkButton>
                            {isResourcesOpen && (
                                <Dropdown
                                    hideOnUrlClick
                                    onClickOutside={this.hideMenu}
                                    placement={isMobile || isTablet ? 'bottom-left' : 'bottom-center'}
                                    seleniumId="resources-dropdown"
                                >
                                    <ResourcesMenu />
                                </Dropdown>
                            )}
                        </DropdownContainer>
                    </div>

                    <div className="menu-spacer" />

                    <div className="menu-search" data-seleniumid="menu-search">
                        <MenuSearch />
                    </div>

                    <div
                        className={cn('menu-avatar', { 'menu-avatar--auth': isAuth })}
                        data-seleniumid="user-name-container"
                    >
                        <div className="UserName">
                            {isAuth && (
                                <DropdownContainer className="UserName-Dropdown">
                                    <LinkButton
                                        className="UserName-text"
                                        data-seleniumid="user-name"
                                        onClick={() =>
                                            this.setState({ isResourcesOpen: false, isUserNameOpen: !isUserNameOpen })
                                        }
                                    >
                                        <div>
                                            {user.givenName} {user.familyName}
                                        </div>
                                        <SvgIcon.chevron
                                            className="UserName-icon"
                                            iconDescr="Arrow down"
                                            iconTitle="profile menu"
                                        />
                                    </LinkButton>
                                    {isUserNameOpen && (
                                        <Dropdown
                                            hideOnUrlClick
                                            onClickOutside={this.hideMenu}
                                            placement={isMobile || isTablet ? 'bottom-left' : 'bottom-center'}
                                            seleniumId="user-name-dropdown"
                                        >
                                            <LinkButtonOrLink
                                                className="UserName-Dropdown-button"
                                                data-seleniumid="menu-profile-btn"
                                                href={routes.profile}
                                            >
                                                {l(`${ID.BUTTONS}.TOP_MENU.PROFILE`)}
                                            </LinkButtonOrLink>
                                            <LinkButton
                                                className="UserName-Dropdown-button"
                                                data-seleniumid="menu-logout-btn"
                                                onClick={this.onLogout}
                                            >
                                                {l(`${ID.BUTTONS}.TOP_MENU.LOGOUT`)}
                                            </LinkButton>
                                        </Dropdown>
                                    )}
                                </DropdownContainer>
                            )}

                            {!isAuth && (
                                <div className="menu-btns" data-seleniumid="menu-buttons">
                                    <LinkButton data-seleniumid="menu-login-btn" onClick={this.goToLogin}>
                                        {l(`${ID.BUTTONS}.TOP_MENU.LOGIN`)}
                                    </LinkButton>

                                    <LinkButton data-seleniumid="menu-register-btn" onClick={this.goToRegistration}>
                                        {l(`${ID.BUTTONS}.TOP_MENU.REGISTER`)}
                                    </LinkButton>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { Menu };
export default withAuth(withCodes(withResponsive(Menu), ID.BUTTONS));
