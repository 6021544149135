class EventEmitter {
    constructor() {
        this.storage = {};
    }

    emit(event, ...args) {
        if (this.storage[event]) {
            this.storage[event].forEach(fn2 => fn2(...args));
        }
    }

    subscribe(event, fn) {
        if (!this.storage[event]) {
            this.storage[event] = [];
        }

        const fn2 = (...args) => fn(...args);

        this.storage[event].push(fn2);

        const unsubscribe = () => {
            const index = this.storage[event].indexOf(fn2);

            if (index !== -1) {
                this.storage[event].splice(index, 1);
            }
        };

        return unsubscribe;
    }
}

function EventEmitterDecorator(obj) {
    const o = obj.prototype || obj;

    o.createEmitterIfNeeded = function () {
        if (!this.emitter) {
            this.emitter = new EventEmitter();
        }
    };
    o.emit = function (...args) {
        this.createEmitterIfNeeded();
        this.emitter.emit(...args);
    };
    o.subscribe = function (...args) {
        this.createEmitterIfNeeded();
        return this.emitter.subscribe(...args);
    };
}

export { EventEmitter, EventEmitterDecorator };
export default new EventEmitter();
