export const KeyCodes = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    CTRL: 17,
    ALT: 18,
    PAUSE_BREAK: 19,
    CAPS_LOCK: 20,
    ESC: 27,
    SPACE: 32,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    INSERT: 45,
    DELETE: 46,
    COMMAND: 91,
    LEFT_COMMAND: 91,
    RIGHT_COMMAND: 93,
};

export function getAddressString({ city, stateName, stateCode, country, countryName, countryCode }) {
    return [city, stateName || stateCode, countryCode || countryName || country].filter(item => item).join(', ');
}
