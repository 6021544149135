import axios from 'axios';
import storage from 'app/blocks/common/storage';

const enableAjaxRecorder = () => {
    const history: Record<string, any> = {};

    function getRecentAjaxHistory() {
        return Object.values(history).sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
    }
    window.getRecentAjaxHistory = getRecentAjaxHistory;

    function onSend(config) {
        if (!config.metadata?.doNotTrack) {
            const id = config.metadata?.transactionId;

            history[id] = {
                id,
                url: config.url,
                method: config.method,
                startTime: new Date().getTime(),

                requestHeaders: config.headers,
                requestBody: config.data,
            };
        }

        return config;
    }

    function onComplete(config, response) {
        if (!config.metadata?.doNotTrack) {
            const id = config.metadata?.transactionId;
            const historyItem = history[id];

            if (!historyItem) {
                return;
            }

            const tm = new Date().getTime();
            history[id] = {
                ...historyItem,

                endTime: tm,
                duration: tm - historyItem.startTime,

                status: response?.status,
                statusText: response?.statusText,
                responseHeaders: response?.headers,
                responseBody: response?.data,
            };
        }
    }

    axios.interceptors.request.use(onSend);
    axios.interceptors.response.use(
        response => {
            onComplete(response.config, response);
            return response;
        },
        error => {
            onComplete(error.config, error.response);
            return Promise.reject(error);
        },
    );
};

const isRecordAjaxEnable = () => !!storage.get('recordAjaxRequestsOn');

const recordAjaxTurnOn = () => {
    if (!isRecordAjaxEnable()) {
        storage.set('recordAjaxRequestsOn', true);
        enableAjaxRecorder();
    }
};

export default function initAjaxRecorder() {
    if (isRecordAjaxEnable()) {
        enableAjaxRecorder();
    }
    window.recordAjaxTurnOn = recordAjaxTurnOn;
}
