import React from 'react';
import { createRoot } from 'react-dom/client';
import { ajaxMenuConfig, initApplication } from 'app/blocks/ajax/config/ajax-config';
import { injectDTM } from 'app/blocks/analytics/analytics';
import AuthProvider from 'app/blocks/common/providers/AuthProvider';
import { getQueryParamSafe } from 'app/blocks/common/utils';
import { renderMenu } from 'app/blocks/menu/__renderer/menu__renderer';
import { isMock } from 'app/blocks/middleware/config';
import PageFooter from 'app/blocks/page/__footer/page__footer';
import injectCRM from 'app/blocks/SupportWidget/external';

import './app/styles/utils/text-utils.scss';
import './app/styles/utils/spacing.scss';
import './app/styles/utils/utils.scss';
import './app/styles/baseline.scss';
import './app/styles/global.scss';
import './app/styles/footer.scss';
import './app/styles/uitextloader.scss';
import './app/ui/form/form.scss'; // for combobox search input

ajaxMenuConfig();

async function run() {
    const queryParams = getQueryParamSafe(window.location.href);

    const authReturnPage = queryParams?.code && queryParams?.id_token;
    for (const selector of ['#AS__menu', '#AS__footer', 'body > div.container']) {
        const element: HTMLElement = document.querySelector(selector);
        if (element) {
            element.style.display = authReturnPage ? 'none' : '';
        }
    }
    if (authReturnPage) {
        createRoot(document.body.insertBefore(document.createElement('div'), document.body.firstChild)).render(
            <div>
                <div className="Loading-screen">
                    <div className="loader" />
                    <div style={{ fontSize: '20px', lineHeight: '30px', padding: '20px', textAlign: 'center' }}>
                        Please wait while you are taken to the Wiley Author Services Dashboard.
                    </div>
                </div>
            </div>,
        );
    }

    createRoot(document.getElementById('AS__footer')).render(
        <AuthProvider>
            {renderMenu()}
            <PageFooter />
        </AuthProvider>,
    );

    await initApplication();

    if (!isMock()) {
        if (!window._satellite) {
            injectDTM();
        }

        injectCRM();
    }
}

run();
