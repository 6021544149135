function decorate(decorator) {
    return function (target, key, descriptor) {
        if (!descriptor) {
            // descriptor is not passed in ts code
            const fn = target[key];

            Object.defineProperty(target, key, { value: decorator(fn) });

            return undefined;
        }

        const fn = descriptor.value;

        return {
            ...descriptor,
            value: decorator(fn),
        };
    };
}

function markAsChanged(...args) {
    return decorate(
        fn =>
            async function (...args2) {
                this.isChanged = true;

                return fn.call(this, ...args2);
            },
    )(...args);
}

function markAsUnchanged(...args) {
    return decorate(
        fn =>
            async function (...args2) {
                try {
                    return await fn.call(this, ...args2);
                } finally {
                    this.isChanged = false;
                }
            },
    )(...args);
}

export { markAsChanged, markAsUnchanged };
