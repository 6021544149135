const StepEnum = {
    CONFIRM_AUTHORS: 'confirmAuthors',
    FUNDERS: 'researchFunders',
    AFFILIATIONS: 'articleAffiliations',
    COPYRIGHT: 'copyrightOwnership',
    OPEN_ACCESS_OPTIONS: 'openAccessOption',
    LICENSE_TYPES: 'licenseAgreementTypes',
    DISCOUNT_AND_BILLING: 'discountAndBillingDetails',
    PAYMENT: 'onlineOpenPayment',
    LICENSE_SUBMISSION: 'licenseSubmission',
    CONFIRMATION: 'confirmationPage',
} as const;

export default StepEnum;
