import React from 'react';
import Image from 'app/blocks/image/image';
import PanelActionsButtons from 'app/blocks/Panel/actions/buttons/panel_actions__buttons';

const MenuArticle: React.VFC<{
    close: () => void;
    item: Search.Article;
}> = ({ close, item }) => {
    return (
        <div className="menu__search_Item clearfix">
            <div className="menu__search_Item__text_article">
                <div className="menu__search-item-pic">
                    <Image
                        className="menu__search_Item__img"
                        src={item.doc.journalCoverImage}
                        type={Image.TYPES.JOURNAL}
                    />
                </div>
                <div
                    className="text_bold text_md m-btm_micro"
                    dangerouslySetInnerHTML={{ __html: item.doc.journalTitle }}
                />
                <div dangerouslySetInnerHTML={{ __html: item.doc.title }} />
            </div>

            {item.doc.actions && item.doc.actions.length > 0 && (
                <PanelActionsButtons
                    className="pull-right text-right"
                    classNameButton="menu__search_Item__actions"
                    handler={() => close()}
                    inline
                    items={item.doc.actions}
                    name="search"
                    params={{ ARTICLE_ID: item.id }}
                    seleniumid="menu-search--article"
                />
            )}
        </div>
    );
};

export default MenuArticle;
