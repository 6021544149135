/* eslint-disable @typescript-eslint/no-shadow,@typescript-eslint/no-use-before-define */
// eslint-disable-next-line max-classes-per-file
import update from 'immutability-helper';
import moment from 'moment';
import semverCoerce from 'semver/functions/coerce';
import semverSatisfies from 'semver/functions/satisfies';
import _ from 'underscore';
import velocity from 'velocityjs';

function createPickField(source, fieldPrefix, idField = 'Id', nameField = 'Name') {
    return {
        id: source[fieldPrefix + idField],
        name: source[fieldPrefix + nameField],
    };
}

function readPickField(source, fieldPrefix, idField = 'Id', nameField = 'Name') {
    let id;
    let name;

    if (source === undefined || source === null) {
        id = source;
        name = source;
    } else {
        id = source.id;
        name = source.name;
    }

    return {
        [fieldPrefix + idField]: id,
        [fieldPrefix + nameField]: name,
    };
}

function updateProperty(object, property, value) {
    const holder = {};
    holder[property] = { $set: value };
    return update(object, holder);
}

function updateProperties(object, properties) {
    const holder = {};
    _.each(properties, (value, property) => {
        holder[property] = { $set: value };
    });
    return update(object, holder);
}

function supportCreditCardPayment(browser) {
    if (browser.name === 'safari') {
        return !semverSatisfies(semverCoerce(browser.version), '12.x || 13.x || 14.0.x');
    }

    if (browser.name === 'ios') {
        return !semverSatisfies(semverCoerce(browser.version), '12.x');
    }

    return true;
}

function getQueryParam(inputURL: string, decodeURI: boolean = false): Record<string, string> {
    const queryResult = {};

    if (inputURL.includes('?')) {
        inputURL
            .split('?')[1]
            .split('&')
            .forEach(param => {
                queryResult[param.split('=')[0]] = decodeURI
                    ? decodeURIComponent(param.split('=')[1])
                    : param.split('=')[1];
            });
    }

    return queryResult;
}

function queryStringIsNotEmpty(inputURL) {
    return inputURL.split('?')[1] !== undefined;
}

function getQueryParamSafe(inputURL, ...args) {
    try {
        return inputURL && queryStringIsNotEmpty(inputURL) ? getQueryParam(inputURL, ...args) : {};
    } catch (error) {
        return {};
    }
}

const network = {
    getErrorFromFetchResponse(response) {
        return (response && response.responseJSON && response.responseJSON.error) || {};
    },
};

const DATE_PAIR_ERROR_CODES = {
    EQUAL_DATES: 'EQUAL_DATES',
    INVALID_FROM_DATE: 'INVALID_FROM_DATE',
    INVALID_FROM_DATE_FUTURE: 'INVALID_FROM_DATE_FUTURE',
    INVALID_FROM_DATE_GREATER_TO_DATE: 'INVALID_FROM_DATE_GREATER_TO_DATE',
    INVALID_TO_DATE: 'INVALID_TO_DATE',
};

function setStateAsPromise(newProps, handler): Promise<void> {
    return new Promise(resolve => {
        this.setState(newProps, () => {
            if (handler) {
                handler();
            }
            resolve();
        });
    });
}

const HUMAN_FRIENDLY_FORMAT = 'D MMMM, YYYY'; // fixme: review usages and confirm if it should be "LL" to international support
const ISO_FORMAT = 'YYYY-MM-DD';
const MINIMAL_DATE = new Date('1900-01-01');

function date(date?: moment.MomentInput, defaultValue?: string) {
    if (!date) {
        return defaultValue || '';
    }
    return moment(date).format(HUMAN_FRIENDLY_FORMAT);
}

function isoFormattedDateString(date, defaultValue) {
    if (!date) {
        return defaultValue || '';
    }
    return moment(date).format(ISO_FORMAT);
}

function isValidDateString(dateString) {
    return moment(dateString, HUMAN_FRIENDLY_FORMAT, true).isValid() || moment(dateString, ISO_FORMAT, true).isValid();
}

function isValidDate(date) {
    return (
        date !== null &&
        // eslint-disable-next-line no-restricted-globals
        ((!isNaN(date) || !_.isEmpty(date)) && typeof date === 'string'
            ? isValidDateString(date)
            : !Number.isNaN(new Date(date).getTime()))
    );
}

function validateAndGetDateFormattedValue(date, defaultValue, format) {
    return isValidDate(date) ? moment(date).format(format) : defaultValue;
}

function validateAndGetDateObject(date, defaultValue) {
    return isValidDate(date) ? moment(date).toDate() : defaultValue;
}

function validateAndGetDateMs(date, defaultValue) {
    return isValidDate(date) ? new Date(date).getTime() : defaultValue;
}

function strToDate(str, format = HUMAN_FRIENDLY_FORMAT) {
    return moment(str, format).toDate();
}

function validateDatePair(fromDateString, toDateString) {
    const isEmptyFromDate = fromDateString === '';
    const isEmptyToDate = toDateString === '';
    const errorList = [];
    let isDatesValid = true;
    let isDatesFormatValid = true;

    if (!isEmptyFromDate || !isEmptyToDate) {
        const fromDate = strToDate(fromDateString);
        const toDate = strToDate(toDateString);

        if (!isEmptyFromDate) {
            if (!isValidDateString(fromDateString)) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_FROM_DATE);
                isDatesFormatValid = false;
            } else if (fromDate < MINIMAL_DATE) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_FROM_DATE);
                isDatesValid = false;
            } else if (fromDate > new Date()) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_FROM_DATE_FUTURE);
                isDatesValid = false;
            }
        }

        if (!isEmptyToDate) {
            if (!isValidDateString(toDateString)) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_TO_DATE);
                isDatesFormatValid = false;
            } else if (toDate < MINIMAL_DATE) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_TO_DATE);
                isDatesValid = false;
            }

            if (isEmptyFromDate) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_FROM_DATE);
                isDatesValid = false;
            }
        }

        if (isDatesFormatValid) {
            if (!isEmptyToDate && fromDate > toDate) {
                errorList.push(DATE_PAIR_ERROR_CODES.INVALID_FROM_DATE_GREATER_TO_DATE);
                isDatesValid = false;
            } else if (!isEmptyToDate && fromDate.getTime() === toDate.getTime()) {
                errorList.push(DATE_PAIR_ERROR_CODES.EQUAL_DATES);
                isDatesValid = false;
            }
        } else {
            isDatesValid = false;
        }
    }

    return { errorList, isDatesFormatValid, isDatesValid };
}

function getUncompletedPanels(panels) {
    panels.forEach(panel => panel.updateTopPosition && panel.updateTopPosition());
    return _.filter(panels, panel => !panel.isComplete()).sort((a, b) => {
        if (a.topPosition < b.topPosition) {
            return -1;
        }
        if (a.topPosition > b.topPosition) {
            return 1;
        }
        return 0;
    });
}

function stripHtml(string) {
    return string.replace(/(<([^>]+)>)/gi, '');
}

function replaceLineBreakSymbolsToBrTag(string) {
    return string.replace(/(\r\n|\n|\r)/gm, '<br />');
}

function setLinksToOpenInNewWindow(string) {
    return string.replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ');
}

/**
 * Clones object
 * doesn't resolve dates correctly and fails on circular structures
 */
function cloneDeepSimple(obj) {
    return JSON.parse(JSON.stringify(obj));
}

function plainValueDiff(object1, object2) {
    const diff = [];
    let value1;
    let value2;

    if (!_.isEmpty(object1)) {
        _.each(object1, (item, key) => {
            value1 = item;
            value2 = (object2 && object2[key]) || '';
            if (value1 !== value2) {
                diff.push({ key, value1, value2 });
            }
        });
    }

    if (!_.isEmpty(object2)) {
        _.each(object2, (item, key) => {
            value1 = (object1 && object1[key]) || '';
            value2 = item;
            if (value1 !== value2 && !_.findWhere(diff, { key })) {
                diff.push({ key, value1, value2 });
            }
        });
    }

    return diff;
}

function looseEqual(v1, v2) {
    if (v1 === v2) return true;
    const toNullValue = val => {
        if (Array.isArray(val) && val.length === 0) return null;
        if (val instanceof Object && Object.keys(val).length === 0) return null;
        if (val === undefined) return null;
        return val;
    };
    const value1 = toNullValue(v1);
    const value2 = toNullValue(v2);
    const isSimple1 = !(value1 instanceof Object) && !(value1 instanceof Function);
    const isSimple2 = !(value2 instanceof Object) && !(value2 instanceof Function);
    if (isSimple1 && isSimple2) {
        // we need == instead of ===
        // eslint-disable-next-line eqeqeq
        if (value1 == value2 && (!!value1 || !!value2)) return true;
        if (value1 === null && !value2) return true;
        if (value2 === null && !value1) return true;
    }

    const v1IsObject = !isSimple1 && value1 instanceof Object && !Array.isArray(value1);
    const v2IsObject = !isSimple2 && value2 instanceof Object && !Array.isArray(value2);

    if (v1IsObject || v2IsObject) {
        const obj1 = value1 || {};
        const obj2 = value2 || {};
        const keys = _.union(Object.keys(obj1), Object.keys(obj2));
        return keys.length === 0 || keys.findIndex(key => !looseEqual(obj1[key], obj2[key])) < 0;
    }
    return _.isEqual(value1, value2);
}

// workaround for browsers that doesn't work correctly with placeholders (e.g. Safari 10.0.3)
// this function is used to remove placeholder if value is set and show when value is empty
const removePlaceholderOnEmptyValue = (value, placeholderText) => (value ? '' : placeholderText);

function template(target, params = {}) {
    let message = target;

    try {
        if (target.includes('<%')) {
            message = _.template(target)(params);
        } else if (target.includes('$') || target.includes('#')) {
            // @ts-ignore
            message = velocity.render(target, params, undefined, { escape: false });
        }
    } catch (error) {
        console.log(error);
    }

    return message;
}

function windowLocationReplace(url) {
    window.location.replace(decodeURIComponent(url));
}

function trimStringProperties(obj) {
    const out = { ...obj };

    Object.entries(out).forEach(([key, value]) => {
        if (typeof value === 'string') {
            out[key] = value.trim();
        }
    });

    return out;
}

const getDisplayName = component => component.displayName || component.name || 'Component';

// https://github.com/reactjs/reactjs.org/issues/16
const normalizeInputValue = val => (val === null || val === undefined ? '' : val);

const getSortArrFromObject = obj =>
    Object.keys(obj || {})
        .map(fieldName => ({
            _id: fieldName,
            ...obj[fieldName],
            order: Number(obj[fieldName].order) || 0,
        }))
        .sort((a, b) => a.order - b.order);

const getFocusableChildren = parentElement => {
    const elements = parentElement.querySelectorAll(`
            a[href]:not([tabindex="-1"]),
            area[href]:not([tabindex="-1"]),
            input:not([disabled]):not([tabindex="-1"]),
            select:not([disabled]):not([tabindex="-1"]),
            textarea:not([disabled]):not([tabindex="-1"]),
            button:not([disabled]):not([tabindex="-1"]),
            iframe:not([tabindex="-1"]),
            [tabindex]:not([tabindex="-1"]),
            [contentEditable=true]:not([tabindex="-1"])
        `);
    return [...elements];
};

function cleanToken(token) {
    return decodeURIComponent(token).trim();
}

function includesEachWord(str = '', search = '') {
    const strLC = str.toLowerCase();
    const words = search
        .toLowerCase()
        .split(/\s/)
        .filter(w => !!w);

    return words.map(w => strLC.includes(w)).every(i => i);
}

function cleanFields(component, cleaner, postCleaner = s => (s.trim ? s.trim() : s)) {
    return new Promise(resolve => {
        let obj = {};
        component.setState(
            state => {
                obj = cleaner(state);
                Object.keys(obj).forEach(key => {
                    obj[key] = obj[key] ? postCleaner(obj[key]) : obj[key];
                });
                return obj;
            },
            () => resolve(obj),
        );
    });
}

function strToBool(str) {
    return str === 'true';
}

// cb(0), cb(100) will be raised in any case
// todo: other logic (including backend 202 and long operations with re-checking)
// eslint-disable-next-line default-param-last
async function progressiveRequest<T>(
    request: () => Promise<T> | T,
    configuration: {
        repeat?: boolean;
        repeatOnErrors?: string[];
        delay?: number;
        delayIncrement?: number;
        maxDelay?: number;
        maxRequestTime?: number;
    } = {},
    cbProgress: (percent: number) => void = () => {},
): Promise<T> {
    const config = {
        delay: 2000,
        delayIncrement: 2000,
        maxDelay: 30000,
        maxRequestTime: 60 * 60 * 1000,
        repeat: false,

        repeatOnErrors: null,
        ...configuration,
    };

    const endTime = performance.now() + config.maxRequestTime;
    const timer = async time => new Promise(resolve => setTimeout(resolve, time));

    let delayBetweenAttempts = config.delay;
    let result = null;
    let resultError = null;

    cbProgress(0);

    let percent = 1;
    const fakeInterval = setInterval(() => {
        percent += (100 - percent) / (30 + percent);
        cbProgress(Math.floor(percent));
    }, 1000);

    if (config.repeat) {
        while (true) {
            try {
                resultError = null;
                // eslint-disable-next-line no-await-in-loop
                result = await request();
                break;
            } catch (error) {
                resultError = error;

                // cbProgress(Math.round((Math.round(performance.now()) / (endTime / 2)) * 100));

                if (performance.now() < endTime) {
                    if (!config.repeatOnErrors || config.repeatOnErrors.includes(error.code)) {
                        console.error(`request is not available on attempt, delay ${delayBetweenAttempts}`);
                        // eslint-disable-next-line no-await-in-loop
                        await timer(delayBetweenAttempts);

                        if (delayBetweenAttempts < config.maxDelay) {
                            delayBetweenAttempts += config.delayIncrement;
                        }
                    } else break;
                } else break;
            }
        }
    } else {
        try {
            result = await request();
        } catch (error) {
            resultError = error;
        }
    }

    clearInterval(fakeInterval);

    cbProgress(100);

    if (resultError) {
        throw resultError;
    }

    return result;
}

function keymirror<K extends string, T extends Record<K, any>>(obj: T): { [K in keyof T]: K } {
    Object.keys(obj).forEach(key => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = key;
    });

    return obj;
}

const compose = (...hocs) =>
    hocs.reduce(
        (a, b) =>
            (...args) =>
                a(b(...args)),
        arg => arg,
    );

const compactObject = obj => (!!obj && Object.fromEntries(Object.entries(obj).filter(([, v]) => !!v))) || obj;

class Handlers {
    link(object, method) {
        if (method === 'link') {
            return undefined;
        }
        this[method] = object[method].bind(object);
        return this[method];
    }
}

export {
    Handlers,
    cleanToken,
    cloneDeepSimple,
    compactObject,
    compose,
    createPickField,
    date,
    DATE_PAIR_ERROR_CODES,
    getDisplayName,
    getFocusableChildren,
    getQueryParam,
    getQueryParamSafe,
    getSortArrFromObject,
    getUncompletedPanels,
    HUMAN_FRIENDLY_FORMAT,
    ISO_FORMAT,
    isValidDate,
    isValidDateString,
    keymirror,
    looseEqual,
    network,
    normalizeInputValue,
    plainValueDiff,
    queryStringIsNotEmpty,
    readPickField,
    removePlaceholderOnEmptyValue,
    replaceLineBreakSymbolsToBrTag,
    setLinksToOpenInNewWindow,
    setStateAsPromise,
    stripHtml,
    strToBool,
    strToDate,
    supportCreditCardPayment,
    template,
    trimStringProperties,
    updateProperties,
    updateProperty,
    validateDatePair,
    windowLocationReplace,
    MINIMAL_DATE,
    validateAndGetDateObject,
    validateAndGetDateFormattedValue,
    validateAndGetDateMs,
    includesEachWord,
    cleanFields,
    progressiveRequest,
    isoFormattedDateString,
};
