/**
 * Created by drykovanov on 22.03.2017.
 */
let activeTagLoading = 0;
const DEFAULT_SELECTOR = '#selenium-ajax-indicator';
const DEFAULT_INDICATIONCLASS = 'tag-loading';
const DEFAULT_TAG = 'SCRIPT';
let indicationElement;
let indicationClass;
let targetTag;

const checkAndUpdateIdicationElement = () => {
    if (!indicationElement) {
        return;
    }

    if (activeTagLoading > 0) {
        indicationElement.classList.add(indicationClass);
    } else {
        indicationElement.classList.remove(indicationClass);
    }
};

const bindToLoadEvents = (listenerOperation, handler) => {
    listenerOperation('load', handler);
    listenerOperation('error', handler);
    listenerOperation('abort', handler);
    listenerOperation('unload', handler);
};

const attachEvents = (node, onProcessed) => {
    const onLoadEventsHandler = () => {
        bindToLoadEvents((type, handler) => node.removeEventListener(type, handler, false), onProcessed);
        onProcessed();
    };
    bindToLoadEvents((type, handler) => node.addEventListener(type, handler, false), onLoadEventsHandler);
};

const handleTagLoading = node => {
    activeTagLoading += 1;
    checkAndUpdateIdicationElement();

    const onComplete = () => {
        activeTagLoading -= 1;
        checkAndUpdateIdicationElement();
    };

    attachEvents(node, onComplete);
};

const domInsertedListener = event => {
    const { target } = event;

    if (!target) return;

    const hasSrc = target.attributes && target.attributes.src;
    const isTargetTag = target.tagName === targetTag;
    if (isTargetTag && hasSrc) {
        handleTagLoading(event.target);
    }
};

const enableTagLoaderlistener = (opts: Record<string, string> = {}) => {
    indicationElement = document.querySelector(opts.selector || DEFAULT_SELECTOR);
    indicationClass = opts.indicationClass || DEFAULT_INDICATIONCLASS;
    targetTag = (opts.tag || DEFAULT_TAG).toUpperCase();
    // TODO DomNodeInserted is deprecated, change to MutationObserver
    document.addEventListener('DOMNodeInserted', domInsertedListener, false);
};

export const enableScriptLoaderlistener = () => {
    return enableTagLoaderlistener({ tag: 'script', indicationClass: 'script-loading' });
};

export default enableTagLoaderlistener;
