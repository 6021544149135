import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './image.scss';

const IMAGE_TYPES = {
    JOURNAL: 'journal',
} as const;

type Props = {
    alt?: string;
    className?: string;
    src: string;
    title?: string;
    type: Values<typeof IMAGE_TYPES>;
};

type State = {
    imageLoaded: boolean;
    noImageSet: boolean;
};

export default class Image extends React.Component<Props, State> {
    static propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.oneOf(Object.values(IMAGE_TYPES)).isRequired,
    };

    static defaultProps = {
        alt: '',
        title: '',
    };

    static TYPES = IMAGE_TYPES;

    state: State = {
        imageLoaded: false,
        noImageSet: false,
    };

    onImgLoadError() {
        this.setState({ noImageSet: true });
    }

    onImgLoaded() {
        this.setState({ imageLoaded: true });
    }

    render() {
        const { alt, className, src, title, type } = this.props;
        const { imageLoaded, noImageSet } = this.state;

        const imageLoading = !noImageSet && !imageLoaded && src;

        return (
            <div className={cn('image', className)}>
                <div className={cn('image__inner', `image__inner_${type}`)}>
                    <div
                        className={cn('image__content', {
                            'spinner spinner_adaptive image__content_border-only': imageLoading,
                        })}
                    >
                        {!noImageSet && src && (
                            <img
                                alt={alt}
                                className="image__img"
                                onError={() => this.onImgLoadError()}
                                onLoad={() => this.onImgLoaded()}
                                src={src}
                                title={title}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
