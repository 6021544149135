import axios from 'axios';

const DEFAULT_TIMEOUT = 400;
const DEFAULT_SELECTOR = '#selenium-ajax-indicator';
const ajaxOnClass = 'ajax-on';

let timeout;
let elem;
let waitTimer;
let activeAjaxRequests = [];

const enableAjaxListener = (options: { selector?: string; timeout?: number } = {}) => {
    elem = document.querySelector(options.selector || DEFAULT_SELECTOR);
    timeout = options.timeout || DEFAULT_TIMEOUT;

    function addRequest(id) {
        activeAjaxRequests.push(id);
        elem.classList.add(ajaxOnClass);
    }

    function removeRequest(id) {
        activeAjaxRequests = activeAjaxRequests.filter(x => x !== id);

        if (activeAjaxRequests.length === 0) {
            clearTimeout(waitTimer);

            waitTimer = setTimeout(() => {
                if (activeAjaxRequests.length === 0) {
                    elem.classList.remove(ajaxOnClass);
                }
            }, timeout);
        }
    }

    axios.interceptors.request.use(config => {
        if (!config.metadata?.doNotTrack) {
            addRequest(config.metadata?.transactionId);
        }

        return config;
    });

    axios.interceptors.response.use(
        response => {
            if (!response.config.metadata?.doNotTrack) {
                removeRequest(response.config.metadata?.transactionId);
            }

            return response;
        },
        error => {
            if (!error.config.metadata?.doNotTrack) {
                removeRequest(error.config.metadata?.transactionId);
            }

            return Promise.reject(error);
        },
    );
};

export function isLoadingIndicator() {
    return document.querySelector(DEFAULT_SELECTOR)?.className?.includes(ajaxOnClass);
}

export default enableAjaxListener;
