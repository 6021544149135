/* eslint-disable no-param-reassign */
import appPages from 'app/appPages';
import { findStaticPageInfo, infoUpdated } from 'app/blocks/common/as-info';
import { compactObject } from 'app/blocks/common/utils';
import { isNonProd } from 'app/blocks/middleware/config';
import sendBeacon from 'app/blocks/riskified/riskified';
import generateLicenseSigning from './licenseSigning';
import generateLogin from './login';
import { generateLicenseSigningPage, generateStaticPage, generateSearchPage, generateSPAPage } from './page';
import generateRegistration from './registration';
import { mapSearchToAnalyticSearchData } from './search';
import generateSite from './site';
import generateUser from './user';

export const pageEvents = {
    LICENSE_SIGNING_INITIATION: 'license-signing-initiation',
    LICENSE_SIGNING_STEP_COMPLETION: 'license-signing-step-completion',
    LOGIN_ERROR: 'login error',
    LOGIN_INITIATE: 'login initiate',
    LOGIN_SUCCESS: 'login success',
    PAGE_LOAD: 'page-load',
    REGISTRATION_INITIATED: 'registration-initiated',
    REGISTRATION_FORM_ERROR: 'registration-form-error',
    REGISTRATION_COMPLETED: 'registration-completed',
    SEARCH_RESULTS_PAGE: 'search-results-page',
};

window.adobeDataLayer = window.adobeDataLayer || [];
const { adobeDataLayer } = window;

function reset() {
    adobeDataLayer.length = 0;
}

let currentPath = null;

const postponed = [];

function firePostponed() {
    setTimeout(() => {
        postponed.forEach(evt => adobeDataLayer.event.push(evt));
    }, 1000);
}

async function fireEvent(event, data, error) {
    sendBeacon();

    adobeDataLayer.push({
        event,
        ...data,
        ...error,
    });
}

async function pageChanges() {
    const info = findStaticPageInfo();
    if (!info) {
        return;
    }
    const { path, title } = info;
    const pageChanged = currentPath !== path;

    if (pageChanged) {
        currentPath = path;

        if (path) {
            await fireEvent(pageEvents.PAGE_LOAD, {
                page: generateStaticPage(path),
                site: generateSite(),
                user: await generateUser(),
            });
            infoUpdated({ page: title, path });
        }
    }
}

export function trackDTM(message) {
    try {
        if (window?._satellite?.track) {
            window._satellite.track(message);
        }
    } catch (error) {
        console.error(error);
    }
}

export function injectDTM() {
    const script = document.createElement('script');
    script.src = isNonProd()
        ? '//assets.adobedtm.com/59640f9fa510/9f689aa629f1/launch-312a256c5f20-development.min.js'
        : '//assets.adobedtm.com/59640f9fa510/9f689aa629f1/launch-ace7d57e47bd.min.js';
    script.async = true;

    script.onload = firePostponed;
    script.onerror = firePostponed;

    document.body.append(script);
}

let changeTimer = null;
const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
if (MutationObserver) {
    new MutationObserver(() => {
        if (!changeTimer) {
            changeTimer = setTimeout(() => {
                changeTimer = null;
                pageChanges();
            }, 500);
        }
    }).observe(document, {
        subtree: true,
        childList: true,
    });
}

export async function processSignLicenseStepLoad(data, pageEvent = pageEvents.PAGE_LOAD, error = undefined) {
    const eventData = compactObject({
        'license-submission': generateLicenseSigning(data),
        page: generateLicenseSigningPage(data?.step?.name),
        site: generateSite(),
        user: await generateUser(),
        ...error,
    });
    await fireEvent(pageEvent, eventData);
}

export async function processSignLicenseClick(data, pageEvent = pageEvents.LICENSE_SIGNING_STEP_COMPLETION) {
    reset();
    await fireEvent(pageEvent, {
        'license-signing': generateLicenseSigning(data),
    });
}

export async function processSearchResultsPage(data) {
    const eventData = compactObject({
        search: mapSearchToAnalyticSearchData(data),
        page: generateSearchPage(),
        site: generateSite(),
        user: await generateUser(),
    });
    await fireEvent(pageEvents.SEARCH_RESULTS_PAGE, eventData);
}

export async function processPageLoad(pageName, error) {
    reset();
    const eventData = compactObject({
        page: generateSPAPage(pageName),
        site: generateSite(),
        user: await generateUser(),
    });
    await fireEvent(pageEvents.PAGE_LOAD, eventData, compactObject(error));
}

export async function processLogin(data, pageEvent = pageEvents.LOGIN_SUCCESS) {
    await fireEvent(pageEvent, { login: generateLogin(data) });
}

export async function processLoginPageLoad() {
    const eventData = compactObject({
        page: generateSPAPage(appPages.LOGIN),
        site: generateSite(),
        user: await generateUser(),
    });
    await fireEvent(pageEvents.PAGE_LOAD, eventData);
}

export async function processRegistration(data, pageEvent = pageEvents.REGISTRATION_INITIATED) {
    await fireEvent(pageEvent, { registration: generateRegistration(data) });
}

export async function processRegistrationPageLoad(isByInvitation = false, data = {}, page = appPages.VERIFY_EMAIL) {
    const pageName = page || (isByInvitation ? appPages.REGISTRATION_BY_INVITE : appPages.REGISTRATION);
    const eventData = compactObject({
        page: generateSPAPage(pageName),
        site: generateSite(),
        user: await generateUser(),
        ...data,
    });
    await fireEvent(pageEvents.PAGE_LOAD, eventData);
}
