import cn from 'classnames';
import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import './InfoLabel.scss';

type Props = {
    children: React.ReactNode;
    className?: string;
    isBold?: boolean;
    isSmall?: boolean;
    seleniumId?: string;
};

function InfoLabel({ children, className, isBold, isSmall, seleniumId }: Props): React.ReactElement {
    return (
        <div
            className={cn('infoLabel', className, {
                text_bold: isBold,
                'infoLabel--small': isSmall,
            })}
            data-seleniumid={seleniumId}
        >
            <SvgIcon.info iconTitle="Info Icon" />
            {children}
        </div>
    );
}

export default InfoLabel;
