import cn from 'classnames';
import React from 'react';
import { AbstractButton } from 'app/blocks/buttons/buttons';
import { withCodes2, ID } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import './ToggleSupportWidgetButton.scss';

function ToggleSupportWidgetButton({
    isChecked,
    l,
    onClick,
}: {
    isChecked?: boolean;
    l: l;
    onClick: (e: MouseEvent | React.MouseEvent) => void;
}) {
    return (
        <AbstractButton
            className={cn('ToggleSupportWidgetButton ignore-react-onclickoutside', {
                'ToggleSupportWidgetButton--checked': isChecked,
            })}
            data-seleniumid="toggleSupportWidgetBtn"
            onClick={onClick}
        >
            <SvgIcon.help iconTitle="Help Icon" />
            {l('SUPPORT_WIDGET.BUTTON.TOGGLE_SUPPORT_WIDGET')}
        </AbstractButton>
    );
}

export default withCodes2(ID.SUPPORT_WIDGET)(ToggleSupportWidgetButton);
