import cn from 'classnames';
import React from 'react';
import './Layout.scss';

type Props = Either<{
    error?: Error & { refId?: string };
    errorMsg?: string;
}> & {
    children?: React.ReactNode;
    className?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    seleniumId?: string;
};

function renderError(errorMsg?: Props['errorMsg'], error?: Props['error']): React.ReactNode {
    const __html = errorMsg || error?.message;
    const refId = error?.refId;

    if (!!__html && typeof __html === 'string') {
        return (
            <>
                <div className="Layout__error_message" dangerouslySetInnerHTML={{ __html }} />
                {refId && <div className="Layout__refId">{`refId: ${refId}`}</div>}
            </>
        );
    }

    return __html;
}

export default function Layout({
    children,
    className,
    error,
    errorMsg,
    isDisabled = false,
    isLoading = false,
    seleniumId,
}: Props): React.ReactElement {
    const errorDom = renderError(errorMsg, error);
    const content = errorDom || children;

    return (
        <div
            className={cn('Layout', className, {
                Layout_loading: isLoading,
                'Layout_error text_md p_mini': !isLoading && !!errorDom,
            })}
            data-seleniumid={seleniumId ? `layout-${seleniumId}` : undefined}
        >
            {!isLoading && <div className="Layout__content">{content}</div>}
            {(isDisabled || isLoading) && <div className="Layout__disabled" />}
            {isLoading && <div className="Layout__loading" />}
        </div>
    );
}
