import React from 'react';
import { O } from 'ts-toolbelt';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { DialogArgs } from 'app/blocks/dialog/dialog-list';
import StaticContent from 'app/blocks/static-content/static-content';

function confirm(
    content: React.ReactNode,
    params?: O.Optional<Omit<DialogArgs, 'html' | 'innerContent' | 'onApprove' | 'onReject' | 'type'>>,
): Promise<boolean> {
    const pparams: Record<string, any> = {};

    if (typeof content === 'string') {
        pparams.html = content;
    } else {
        pparams.innerContent = content;
    }

    return new Promise(resolve => {
        showDialog.confirmation({
            ...params,
            ...pparams,
            onApprove: () => resolve(true),
            onReject: () => resolve(false),
        });
    });
}

function confirmType(
    type: React.ComponentType<any>,
    params?: O.Optional<Omit<DialogArgs, 'onApprove' | 'onReject' | 'type'>>,
): Promise<boolean> {
    return new Promise(resolve => {
        showDialog.modal(closeAnd => ({
            ...params,
            type,
            onApprove: closeAnd(() => resolve(true)),
            onReject: closeAnd(() => resolve(false)),
        }));
    });
}

function confirmStaticContent(key, params = undefined) {
    return confirm(<StaticContent key={key} params={params} src={`${key}.html`} />);
}

export { confirm, confirmType, confirmStaticContent };
