import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import searchProfiles from 'app/blocks/common/searchProfiles.json';
import { REST } from './utils';

declare global {
    namespace Search {
        type Article = {
            id: string;
            doc: {
                [key: string]: any;
                actions: Array<{ code: string }>;
                category: string;
                contribCoAuthors: Array<{ name: string }>;
                contribCorrAuthor: { name: string };
                doi: string;
                editorialRefCode: string;
                journalCoverImage: string;
                journalFormerTitle?: string[];
                journalRevenueModel: string;
                journalTitle: string;
                journalWolUrl: string;
                shareUrl: string;
                title: string;
            };
            type: 'article';
        };

        type Journal = {
            id: string;
            doc: {
                [key: string]: any;
                actions: Array<{ code: string }>;
                authorGuidelinesUrl: string;
                coverImage: string;
                displayFormerElectronicIssn?: string;
                displayFormerPrintIssn?: string;
                displayFormerTitle?: string;
                electronicIssn: string;
                formerTitle?: string[];
                isiImpactFactor: number;
                onlineSubmissionUrl: string;
                printIssn: string;
                revenueModel: string;
                societyName: any;
                suppressIsiImpactFactor: boolean;
                title: string;
                wolUrl: string;
            };
            type: 'journal';
        };

        type Static = {
            id: string; // static url
            doc: {
                content: string | string[];
                title: string;
            };
            type: 'static';
        };

        type Items = {
            article: Article;
            journal: Journal;
            static: Static;
        };

        type Payload = {
            facets: {
                type: { [key in keyof Items]: number };
            };
            items: Array<Article | Journal | Static>;
            total: number;
        };
    }
}

const hlclass = 'search_result_backlight';
const startMarker = uuidv4();
const endMarker = uuidv4();

const backlight = value => {
    if (typeof value === 'string') {
        return _.escape(value)
            .replace(new RegExp(startMarker, 'g'), `<span class="${hlclass}">`)
            .replace(new RegExp(endMarker, 'g'), '</span>');
    }

    if (Array.isArray(value)) {
        return value.map(backlight);
    }

    if (_.isObject(value) && value.name) {
        return { ...value, name: backlight(value.name) };
    }

    return value;
};

function getSearchProfile(query, type) {
    return typeof query === 'object' && query !== null ? searchProfiles[type] : searchProfiles.quick;
}

function getTypeOfRequest(type) {
    if (!type || (Array.isArray(type) && type.length === 0)) {
        return {};
    }

    return { type };
}

export const search = async (
    query: string | { [key: string]: string },
    pagination: { offset: number; size: number },
    type?: 'article' | ('article' | 'journal' | 'static')[],
): Promise<Search.Payload & { hasMore: boolean }> => {
    const searchProfile = getSearchProfile(query, type);

    let body: { [key: string]: string | number | boolean } = {
        hle: endMarker,
        hls: startMarker,
        ...pagination,
    };

    switch (searchProfile) {
        case 'as-quick':
            body = {
                ...body,
                q: query as string,
                ...getTypeOfRequest(type),
                facets: true,
            };
            break;
        case 'as-articles':
            body = {
                ...body,
                ...(query as { [key: string]: string }),
            };
            break;
        case 'as-journals':
            body = {
                ...body,
                ...(query as { [key: string]: string }),
                facets: true,
            };
            break;
        default:
            throw new Error(`${type} is not supported for search`);
    }

    const data: Search.Payload = await REST.GET(`/search/${searchProfile}`, body);

    data.items.forEach(item => {
        Object.keys(item.doc).forEach(key => {
            // eslint-disable-next-line no-param-reassign
            item.doc[key] = backlight(item.doc[key]);
        });
    });

    return {
        ...data,
        hasMore: data.total > pagination.offset + pagination.size,
    };
};

export const journalOnlySearch = async (query, pagination): Promise<Search.Payload> =>
    REST.GET(`/search/as-journals`, { ...query, ...pagination });
