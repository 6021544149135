import cn from 'classnames';
import React from 'react';
import './titles.scss';

function Title(props) {
    const { component: Component, children, className, headerClassName, ...recentProps } = props;
    return (
        <Component className={cn(headerClassName, className)} {...recentProps}>
            {children}
        </Component>
    );
}

type Props = Either<{
    children: React.ReactNode;
    dangerouslySetInnerHTML: { __html: string };
}> & {
    className?: string;
    style?: Record<string, any>;
};

function MainTitle(props: Props) {
    return <Title component="h2" data-seleniumid="main-title" headerClassName="main-title" {...props} />;
}

function SubTitle(props: Props) {
    return <Title component="h3" headerClassName="subtitle" {...props} />;
}

function ItemTitle(props: Props & { component?: keyof JSX.IntrinsicElements }) {
    return <Title component="div" headerClassName="item-title" {...props} />;
}

export { MainTitle, SubTitle, ItemTitle };
