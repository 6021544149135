import { AxiosResponse } from 'axios';
import { template } from 'app/blocks/common/utils';
import { ServiceError } from 'app/blocks/common/utils-errortypes';
import StaticMiddleware from '../static';

type BackendFailureData = {
    status: 'Failure';
    error: { code: string; message: string; refId?: string; payload?: any };
    payload: any;
};

const metadataForUnitTests = { transactionId: 'test-transaction-id' };

async function getMessages(
    code: string,
    payload,
    defMessage,
    defExtended,
): Promise<{ message: string; extendedMessage: string }> {
    const errCodes: Record<string, any> = await StaticMiddleware.getErrorCodes().catch(() => ({}));
    let message;
    let extendedMessage;

    if (errCodes[code]) {
        message = template(errCodes[code], { payload });

        const extMsgByCode = errCodes?.extended?.[code];

        // it could be empty string '' - e.g. to override any messages
        if (extMsgByCode === undefined) {
            extendedMessage = defMessage || defExtended;
        } else {
            extendedMessage = extMsgByCode;
        }
    } else if (defMessage) {
        message = defMessage;
    } else {
        message = errCodes.OTHER_ERROR;
        extendedMessage = defExtended;
    }

    return { message, extendedMessage };
}

async function getCodeFromBackendFailureData(data: BackendFailureData) {
    const errCodes = await StaticMiddleware.getErrorCodes().catch(() => ({ synthetic: false }));
    const { code } = data.error;

    const synthetic = errCodes && errCodes.synthetic;

    if (synthetic) {
        const newCode = Object.keys(synthetic).find(
            c =>
                synthetic[c].code === code && synthetic[c].message && data.error.message.includes(synthetic[c].message),
        );

        if (newCode) {
            return newCode;
        }
    }

    return code;
}

async function handleBackendError(response: AxiosResponse, data: BackendFailureData) {
    const code = await getCodeFromBackendFailureData(data);

    const { message, extendedMessage } = await getMessages(
        code,
        data.payload,
        data.error.message,
        `HTTP Status: ${data.status}, ${response.statusText}`,
    );

    return new ServiceError(
        message,
        code,
        response.config.url,
        // @ts-ignore
        data.error.refId ?? response.config.metadata?.transactionId,
        data.error.payload ?? data.payload,
        extendedMessage,
    );
}

async function handleMalformedBackendResponse(response: AxiosResponse) {
    const code = 'MALFORMED_RESPONSE';
    // @ts-ignore
    const refId = response.config.metatada?.transactionId;

    const { message, extendedMessage } = await getMessages(
        code,
        undefined,
        undefined,
        `HTTP Status: ${response.status}, ${response.statusText}`,
    );

    return new ServiceError(message, code, response.config.url, refId, response.data, extendedMessage);
}

export default async function getPayloadOrFail(deferredResponse: Promise<AxiosResponse>, detailed: boolean = false) {
    try {
        const response = await deferredResponse;

        // @ts-ignore
        const { url, metadata: { transactionId } = metadataForUnitTests } = response.config;

        if (process.env.NODE_ENV !== 'test') {
            console.debug(`ajax-response url: ${url} transaction-id: ${transactionId}`);
        }
        const data = response.data.status ? response.data : { status: 'SUCCESS', payload: response.data };
        if (process.env.NODE_ENV !== 'test') {
            console.debug(data);
        }

        const isSuccess = data.status === 'SUCCESS';
        const isFailure = data.status === 'FAILURE';

        if (isSuccess) {
            const payload = data.payload !== undefined ? data.payload : data;

            if (detailed) {
                return Promise.resolve({
                    payload,
                    statusCode: response.status,
                    getHeader: header => response.headers[header],
                    url,
                });
            }

            return Promise.resolve(payload);
        }

        if (isFailure && data.error) {
            return Promise.reject(await handleBackendError(response, response.data));
        }

        return Promise.reject(await handleMalformedBackendResponse(response));
    } catch (responseError) {
        // No Network error and so on...
        if (!responseError.response) {
            return Promise.reject(
                new ServiceError(
                    responseError.message,
                    responseError.code,
                    responseError.config.url,
                    responseError.config.refId,
                ),
            );
        }

        if (responseError.response.data.status === 'FAILURE') {
            return Promise.reject(await handleBackendError(responseError.response, responseError.response.data));
        }

        const { url, metadata: { transactionId } = metadataForUnitTests } = responseError.response.config;

        // const payload = responseError.payload; // ?
        const payload = undefined;

        const refId = `${transactionId} (request)`;
        // let code = res.errorCode || res.code || `HTTP_CODE_${res.status}`;
        const code = `HTTP_CODE_${responseError.response.status}`;

        const { message, extendedMessage } = await getMessages(
            code,
            payload,
            undefined,
            `HTTP Status: ${responseError.response.status}, ${responseError.response.statusText}`,
        );

        return Promise.reject(new ServiceError(message, code, url, refId, payload, extendedMessage));
    }
}
