import cn from 'classnames';
import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import Button from './Button';
import { getActionsButtons, handleClick } from './panel_actions__buttons.utils';
import './PanelActionsButtons.scss';

type Props = {
    additionalInfo?: Record<string, any>;
    codes: Record<string, any>;
    getContext?: () => Record<string, any>;
    handler: Parameters<typeof handleClick>['2'];
    inline?: boolean;
    inlineAll?: boolean;
    items: Search.Article['doc']['actions'];
    name: string;
    params: Record<string, any>;
    seleniumid: string;
    icons?: Object;
    className?: string;
    classNameButton?: string;
    statusCodes?: Record<string, string>;
};

function PanelActionsButtons({
    codes,
    getContext,
    handler = () => {},
    inline = false,
    inlineAll = false,
    items,
    name,
    params,
    seleniumid,
    icons,
    className,
    classNameButton,
    statusCodes,
    additionalInfo,
}: Props) {
    const buttons = React.useMemo(
        () => getActionsButtons(statusCodes || codes.PANEL, name, items, params, additionalInfo),
        [statusCodes, codes.PANEL, name, items, params, additionalInfo],
    );

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
            className={cn('panelActionsButtons', className, {
                'panelActionsButtons--inline': inline,
                'panelActionsButtons--inlineAll': inlineAll,
            })}
            data-seleniumid={`${seleniumid}-action-buttons`}
            onClick={e => e.stopPropagation()}
            role="toolbar"
        >
            {buttons.map(button => (
                <Button
                    key={button.code}
                    className={classNameButton}
                    icons={icons}
                    item={button}
                    onClick={e => handleClick(e, button, handler, getContext)}
                    seleniumid={seleniumid}
                />
            ))}
        </div>
    );
}

export { PanelActionsButtons };
export default withCodes(PanelActionsButtons, ID.PANEL);
