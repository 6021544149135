/* eslint-disable no-param-reassign */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import packageJSON from 'app/../../../package.json'; // fixme: how to import this better?
import enableAjaxInjection from 'app/blocks/ajax/injection/ajax-injection';
import enableAjaxListener from 'app/blocks/ajax/listener/ajax-listener';
import initAjaxRecorder from 'app/blocks/ajax/recorder/ajax-recorder';
import { preloadCodes } from 'app/blocks/common/codes';
import { refreshUser } from 'app/blocks/middleware/authentication';
import * as middleware from 'app/blocks/middleware/middleware';
import { enableScriptLoaderlistener } from 'app/blocks/tag-load-listener/tag-load-listener';
import versions from './versions';

const isNonProdDomain = () => {
    const EXCLUDE_FOR = /authorservices[.]wiley[.]com/i;
    return !EXCLUDE_FOR.test(document.location.origin);
};

const enableCors = () => {
    axios.defaults.withCredentials = true;
};

export async function fillVersionInfo() {
    versions.UI = packageJSON.version;

    const { appservices } = await middleware.version();
    versions.BE = appservices?.version?.buildVersion;
    versions.ENV = appservices?.version?.environment;
}

const sendTransactionIdToLogRocket = id => {
    if (typeof window.LogRocket !== 'undefined' && typeof window.LogRocket.track === 'function') {
        window.LogRocket.track(`tid_${id}`);
    }
};

export const admixTransactionIdToRequest = () => {
    axios.interceptors.request.use(config => {
        const id = uuidv4();

        config.headers['transaction-id'] = id;

        if (versions.UI) config.headers['UI-Client-Version'] = versions.UI;
        if (versions.BE) config.headers['UI-Service-Version'] = versions.BE;

        config.metadata = { ...config.metadata, transactionId: id };

        sendTransactionIdToLogRocket(id);

        return config;
    });
};

const ajaxMenuConfig = () => {
    enableCors();
    admixTransactionIdToRequest();
    // TODO find out if other things should be turn on too
};

const ajaxAppConfig = () => {
    enableCors();

    const interceptors = [
        () => admixTransactionIdToRequest(),
        () => enableAjaxListener(),
        () => {
            if (isNonProdDomain()) {
                enableAjaxInjection();
            }
        },
        () => initAjaxRecorder(),
    ];
    // axios@1.0.0-alpha.1 interceptors are executed in the reverse order
    interceptors.reverse();
    interceptors.forEach(x => x());

    enableScriptLoaderlistener();
};

export async function initApplication() {
    let criticalError = null;
    async function catchCritical(fn) {
        try {
            await fn();
        } catch (error) {
            criticalError = error;
        }
    }

    await Promise.all([catchCritical(refreshUser), catchCritical(preloadCodes), catchCritical(fillVersionInfo)]);

    return criticalError;
}

export { ajaxMenuConfig, ajaxAppConfig };
