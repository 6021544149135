enum DashboardActionCodes {
    // OO Order action codes

    CREATE_OO_ORDER = 'CREATE_OO_ORDER',
    CREATE_OO_ORDER_VCH = 'CREATE_OO_ORDER_VCH',
    SUBMIT_OO_ORDER = 'SUBMIT_OO_ORDER',
    REQUEST_OO_ORDER = 'REQUEST_OO_ORDER',
    EDIT_OO_ORDER = 'EDIT_OO_ORDER',
    VIEW_OO_ORDER = 'VIEW_OO_ORDER',
    VIEW_OO_REQUEST = 'VIEW_OO_REQUEST',

    DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE',
    DOWNLOAD_RECEIPT = 'DOWNLOAD_RECEIPT',
    DOWNLOAD_ORDER_CONFIRMATION_DOCUMENT = 'DOWNLOAD_ORDER_CONFIRMATION_DOCUMENT',

    CONTACT_SUPPORT = 'CONTACT_SUPPORT',
    CANCEL_OO_ORDER = 'CANCEL_OO_ORDER',
    CANCEL_OO_DRAFT = 'CANCEL_OO_DRAFT',
    VIEW_EDIT_OO_ORDER = 'VIEW_EDIT_OO_ORDER',
    CONFIRM_OO_ORDER = 'CONFIRM_OO_ORDER',

    COMPLETE_REQUIRED_STEPS_VCH = 'COMPLETE_REQUIRED_STEPS_VCH',

    // Publication action codes

    VIEW_FULL_ARTICLE = 'VIEW_FULL_ARTICLE',
    VIEW_FULL_ARTICLE_PAGE = 'VIEW_FULL_ARTICLE_PAGE',
    SHARE_ARTICLE_LINK = 'SHARE_ARTICLE_LINK',

    // License action codes

    SIGN_LICENSE = 'SIGN_LICENSE',
    VIEW_LICENSE = 'VIEW_LICENSE',
    UPLOAD_LICENSE = 'UPLOAD_LICENSE',
    DOWNLOAD_LICENSE = 'DOWNLOAD_LICENSE',
    EDIT_LICENSE = 'EDIT_LICENSE',

    // OA Order action codes

    CREATE_OA_ORDER = 'CREATE_OA_ORDER',
    SUBMIT_OA_ORDER = 'SUBMIT_OA_ORDER',
    EDIT_OA_ORDER = 'EDIT_OA_ORDER',
    VIEW_OA_ORDER = 'VIEW_OA_ORDER',
    VIEW_EDIT_OA_ORDER = 'VIEW_EDIT_OA_ORDER',
    CONTINUE_GOA_ORDER = 'CONTINUE_GOA_ORDER',

    // GOA Order action codes

    CREATE_GOA_ORDER = 'CREATE_GOA_ORDER',
    SUBMIT_GOA_ORDER = 'SUBMIT_GOA_ORDER',
    EDIT_GOA_ORDER = 'EDIT_GOA_ORDER',
    VIEW_GOA_ORDER = 'VIEW_GOA_ORDER',
    VIEW_GEDIT_GOA_ORDER = 'VIEW_GEDIT_GOA_ORDER',
    CANCEL_GOA_ORDER = 'CANCEL_GOA_ORDER',
    CANCEL_GOA_DRAFT = 'CANCEL_GOA_DRAFT',

    // Additional action codes

    MAKE_VIDEO_ABSTRACT = 'MAKE_VIDEO_ABSTRACT',
    ORDER_REPRINT = 'ORDER_REPRINT',

    // Affiliation / Research Funder action codes

    CHANGE_AFFILIATION_DATA = 'CHANGE_AFFILIATION_DATA',

    // Article confirmation action codes

    CONFIRM_ARTICLE = 'CONFIRM_ARTICLE',
    REJECT_ARTICLE = 'REJECT_ARTICLE',

    // Proofing
    VIEW_PROOFS = 'VIEW_PROOFS',
    REVIEW_PROOFS = 'REVIEW_PROOFS',
}

export default DashboardActionCodes;
