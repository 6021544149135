import React from 'react';

const MenuStatic: React.VFC<{ item: Search.Static }> = ({ item }) => {
    const text = Array.isArray(item.doc.content)
        ? `${item.doc.content.reduce((s, v2) => `${s}...${v2}`, '')}...`
        : `${item.doc.content}...`;

    return (
        <div className="menu__search_Item">
            <div className="menu__search_Item__text">
                <div className="text_bold text_md m-btm_micro" dangerouslySetInnerHTML={{ __html: item.doc.title }} />
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </div>
    );
};

export default MenuStatic;
