import cn from 'classnames';
import React from 'react';
import { processing } from 'app/blocks/common/decorators';
import { ActionButton } from './panel_actions__buttons.utils';

function DefaultButton({ button, disabled, icons, className, children, seleniumid, ...props }) {
    return (
        <a
            {...props}
            className={cn('btn panelActionsButtons-button', className, {
                'btn-white': !button.primary,
                'btn-blue': button.primary,
                'btn-left-icon': button.icon && icons && icons[button.icon],
                disabled,
            })}
            data-seleniumid={`${seleniumid}-${button.code}`}
        >
            {button.icon && icons && icons[button.icon]}
            {children}
        </a>
    );
}

type Props = {
    item?: ActionButton;
    Control?: React.ComponentType<{
        button: ActionButton;
        className?: string;
        disabled?: boolean;
        onClick?: (...args: any) => void;
        [key: string]: any;
    }>;
    className?: string;
    href?: string;
    onClick?: (...args: any) => void;
    seleniumid?: string;
    target?: string;
    [key: string]: any;
};

export default class Action extends React.PureComponent<Props, { isProcessing: boolean }> {
    static defaultProps = {
        Control: DefaultButton,
    };

    state = {
        isProcessing: false,
    };

    @processing('isProcessing')
    async onClickSingle(...args) {
        await (this.props.onClick || this.props.item?.onClick)?.(...args);
    }

    onClick = (e, ...args) => {
        if (this.state.isProcessing) {
            e.preventDefault();
            return false;
        }
        e.target.blur();
        this.onClickSingle(e, ...args);
        return true;
    };

    render() {
        const { item, Control, className, children, ...props } = this.props;
        const { isProcessing } = this.state;

        if (item.url && !props.href) {
            props.href = item.url;
        }
        if (item?.target && !props.target) {
            props.target = item.target;
        }

        return (
            <Control
                button={item}
                className={cn(className, { disabled: isProcessing })}
                disabled={isProcessing}
                {...props}
                onClick={this.onClick}
            >
                {children || (isProcessing ? item.titleOnProgress : item.title)}
            </Control>
        );
    }
}
