import memoize from 'memoize-one';
import { EventEmitterDecorator } from 'app/blocks/common/events/emitter';

export const isEnabled = memoize(() => {
    let enabled = false;

    try {
        const testKey = '__AS2-localStorage-check';
        const testValue = 'test';

        localStorage.setItem(testKey, testValue);
        enabled = testValue === localStorage.getItem(testKey);
        localStorage.removeItem(testKey);
    } catch (e) {
        return false;
    }

    return enabled;
});

function doIfEnabled(fn) {
    if (isEnabled()) {
        return fn();
    }

    return undefined;
}

const stringify = item => {
    switch (typeof item) {
        case 'object':
            return JSON.stringify(item);
        default:
            return String(item);
    }
};

/**
 * returns number not a string if saves number as a string
 */
const parse = item => {
    try {
        return JSON.parse(item);
    } catch (error) {
        return item;
    }
};

@EventEmitterDecorator
class Storage {
    constructor() {
        // @ts-ignore
        window.addEventListener('storage', event => this.emit(event.key, event));
    }

    set(key: string, value: any) {
        doIfEnabled(() => localStorage.setItem(key, stringify(value)));
    }

    get(key: string): any {
        return doIfEnabled(() => parse(localStorage.getItem(key)));
    }

    remove(key: string) {
        doIfEnabled(() => localStorage.removeItem(key));
    }

    clear() {
        doIfEnabled(() => localStorage.clear());
    }

    watch(key: string, fn: (newValue: any, oldValue: any) => void) {
        // @ts-ignore
        return this.subscribe(key, (event: StorageEvent) => {
            fn(parse(event.newValue), parse(event.oldValue));
        });
    }
}

export { Storage };
export default new Storage();
