import cn from 'classnames';
import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';

type Props = {
    children: React.ReactNode;
    l: l;
    toggle: () => void;
    visible?: boolean;
};

function ExtendedMessage({ children, l, toggle, visible = false }: Props) {
    if (!children) {
        return <div />;
    }

    const onToggle = (e: MouseEvent | React.MouseEvent) => {
        e.preventDefault();
        toggle();
    };

    return (
        <div className={cn('DialogExtendedMessage', { 'show-extended-message': visible })}>
            <div className="DialogExtendedMessage-ShowMore">
                {/* eslint-disable-next-line */}
                <a className="id-dialog-more-information" data-seleniumid="dialog-more-information" onClick={onToggle}>
                    {visible ? l('BUTTONS.LESS_DETAILS') : l('BUTTONS.MORE_DETAILS')}
                </a>
            </div>
            <div
                className={cn('DialogExtendedMessage-Message', { hidden: !visible })}
                data-seleniumid="dialog-details-message"
            >
                {children}
            </div>
        </div>
    );
}

export default withCodes(ExtendedMessage, ID.BUTTONS);
