/* eslint-disable max-classes-per-file */
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import listensToClickOutside from 'react-onclickoutside';
import { onEnterKeyPress } from 'app/blocks/common/key-utils';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

import './Dropdown.scss';

const PlacementEnum = {
    BOTTOM_RIGHT: 'bottom-right',
    BOTTOM_CENTER: 'bottom-center',
    BOTTOM_LEFT: 'bottom-left',
    TOP_RIGHT: 'top-right',
};

class Dropdown extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        hideOnUrlClick: PropTypes.bool,
        onClickOutside: PropTypes.func,
        placement: PropTypes.string,
        style: PropTypes.shape({}),
    };

    static defaultProps = {
        children: null,
        hideOnUrlClick: false,
        onClickOutside: () => {},
        placement: PlacementEnum.BOTTOM_RIGHT,
        style: undefined,
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    handleClickOutside = () => this.props.onClickOutside();

    onClick = e => {
        if (this.props.hideOnUrlClick && e?.target?.tagName === 'A') {
            setTimeout(this.handleClickOutside, 0);
        }
    };

    render() {
        const { children, className, placement, style, onClose, seleniumId } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <section
                className={cn('Dropdown', placement, className)}
                data-seleniumid={seleniumId}
                onClick={this.onClick}
                style={style}
            >
                <div className={cn('Dropdown-Arrow', placement)} />

                {onClose && (
                    <div
                        className="Dropdown-CloseButton"
                        onClick={onClose}
                        onKeyPress={onEnterKeyPress(onClose)}
                        role="button"
                        tabIndex="0"
                    >
                        <SvgIcon.cancel />
                    </div>
                )}
                {children}
            </section>
        );
    }
}

function DropdownContainer({ children, className, style, ...props }) {
    return (
        <div
            {...props}
            className={cn(className, 'ignore-react-onclickoutside')}
            style={{ display: 'inline-block', position: 'relative', ...style }}
        >
            {children}
        </div>
    );
}

export { DropdownContainer, PlacementEnum };
export default listensToClickOutside(Dropdown);
