import cn from 'classnames';
import React from 'react';

type Props = {
    className?: string;
    isError?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeValue?: (value: string) => void;
    style?: Record<string, string | number>;
    [prop: string]: any;
};

export default React.forwardRef<HTMLInputElement, Props>(
    ({ className, isError, onChange, onChangeValue, style, ...props }, ref) => (
        <label className={cn(className, 'Radio', { 'Radio--error': isError })} style={style}>
            <input
                {...props}
                ref={ref}
                onChange={event => {
                    if (onChange) {
                        onChange(event);
                    }

                    if (onChangeValue) {
                        onChangeValue(event.target.value);
                    }
                }}
                type="radio"
            />
            <span className="FormControlIcon">
                <svg
                    focusable="false"
                    shapeRendering="geometricPrecision"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect height="20" rx="10" width="20" />
                    <rect fill="#ffffff" height="18" rx="9" width="18" x="1" y="1" />
                    <path
                        className="HoverIndicator"
                        d="M 10 1 C 5.1466973 1 1.2254047 4.8065772 1.0195312 9.6074219 C 2.6415182 6.2844648 6.0401384 4 10 4 C 13.959862 4 17.358482 6.2844648 18.980469 9.6074219 C 18.774595 4.8065772 14.853303 1 10 1 z"
                    />
                    <rect className="CheckedIndicator" height="12" rx="6" width="12" x="4" y="4" />
                </svg>
            </span>
        </label>
    ),
);
