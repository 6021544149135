import { useEffect } from 'react';
import emitter from 'app/blocks/common/events/emitter';
import { compactObject } from 'app/blocks/common/utils';

const InfoChangedEvent = 'AS_INFO_CHANGED';
const infoId = 'as__info';
const PAGE_CLASS = 'page__analytics';

const infoUpdated = data => {
    const info = compactObject(data);
    emitter.emit(InfoChangedEvent, info);
    const infoElement = document.getElementById(infoId);
    if (infoElement) {
        infoElement.replaceChildren(JSON.stringify(info));
    }
};

function ASInfo({ article = null, journal = null, other = null, page = null }) {
    useEffect(() => {
        infoUpdated({ article, journal, other, page });
    }, [article, journal, other, page]);

    return null;
}

const findInfo = () => {
    try {
        return JSON.parse(document.getElementById(infoId)?.innerText);
        // eslint-disable-next-line no-empty
    } catch {}
    return null;
};

const findStaticPageInfo = () => {
    try {
        return JSON.parse((document.querySelector(`.${PAGE_CLASS}`) as HTMLDivElement).innerText);
        // eslint-disable-next-line no-empty
    } catch {}
    return null;
};

const findAnyInfo = () => {
    return findInfo() || findStaticPageInfo();
};

function watchInfo(fn) {
    if (fn) {
        try {
            const info = findAnyInfo();
            fn(info);
            // eslint-disable-next-line no-empty
        } catch {}
    }

    return emitter.subscribe(InfoChangedEvent, fn);
}

export default ASInfo;

export { findStaticPageInfo, InfoChangedEvent, infoUpdated, watchInfo };
