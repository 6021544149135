import get from 'lodash.get';
import React, { Component } from 'react';
import _ from 'underscore';
import skipUnmountedSetStateWarning from 'app/blocks/common/decorators/skipUnmountedSetStateWarning';
import { getDisplayName, keymirror, template } from 'app/blocks/common/utils';
import Layout from 'app/blocks/Layout/Layout';
import middlewareStatic from 'app/blocks/middleware/static';

const ID = keymirror({
    ADDRESS_DOCTOR: '',
    ARTICLE: '',
    ARTICLE_AFFILIATION_EDITOR: '',
    ARTICLE_AFFILIATION_HELP_WIDGET: '',
    BUTTONS: '',
    COMBOBOX: '',
    COMMON: '',
    DASHBOARD: '',
    DASHBOARD_LEFT_SIDE: '',
    DIALOG: '',
    DISCOUNT_WIDGET: '',
    EMAIL_ALERTS: '',
    ERROR: '',
    FIELD_NAMES: '',
    FULL_LICENSE: '',
    FUNDER: '',
    INSTITUTION_PICKER: '',
    LICENSE: '',
    LICENSE_SELECTION_GRID: '',
    LICENSE_SIGNING: '',
    LICENSE_SUBMISSION: '',
    LOGIN: '',
    ORDERS: '',
    ORDER_PANELS: '',
    PANEL: '',
    PANEL_STATUS: '',
    PROFILE: '',
    PROFILE_ACCOUNT: '',
    PROMT: '',
    RECOVER_PASSWORD: '',
    REGISTRATION: '',
    RESET_PASSWORD: '',
    SEARCH_CODES: '',
    SERVICE_NAMES: '',
    SIMPLE_PANEL: '',
    STATE_MANDATORY_COUNTRIES: '',
    SUPPORT_WIDGET: '',
    TOOLTIP: '',
    UPLOAD_LICENSE: '',
    VAT_TAX: '',
    WIDGETS: '',
});

const storage = {
    [ID.COMMON]: () => middlewareStatic.getCommonCodes(),
    [ID.LOGIN]: () => middlewareStatic.getLoginCodes(),
    [ID.PROFILE]: () => middlewareStatic.getProfileCodes(),
    [ID.PROFILE_ACCOUNT]: () => middlewareStatic.getProfileAccountCodes(),
    [ID.RECOVER_PASSWORD]: () => middlewareStatic.getRecoverPasswordCodes(),
    [ID.REGISTRATION]: () => middlewareStatic.getRegistrationCodes(),
    [ID.RESET_PASSWORD]: () => middlewareStatic.getResetPasswordCodes(),
    [ID.FUNDER]: () => middlewareStatic.getFunderCodes(),
    [ID.ERROR]: () => middlewareStatic.getErrorCodes(),
    [ID.FIELD_NAMES]: () => middlewareStatic.getFieldNames(),
    [ID.PANEL]: () => middlewareStatic.getPanelCodes(),
    [ID.PANEL_STATUS]: () => middlewareStatic.getPanelStatusCodes(),
    [ID.DASHBOARD]: () => middlewareStatic.getDashboardCodes(),
    [ID.DASHBOARD_LEFT_SIDE]: () => middlewareStatic.getDashboardLeftSideCodes(),
    [ID.ORDERS]: () => middlewareStatic.getOrdersCodes(),
    [ID.FULL_LICENSE]: () => middlewareStatic.getFullLicenseCodes(),
    [ID.ORDER_PANELS]: () => middlewareStatic.getOrderPanelsCodes(),
    [ID.BUTTONS]: () => middlewareStatic.getButtonsCodes(),
    [ID.PROMT]: () => middlewareStatic.getPrompt(),
    [ID.TOOLTIP]: () => middlewareStatic.getTooltip(),
    [ID.LICENSE]: () => middlewareStatic.getLicenseCodes(),
    [ID.LICENSE_SELECTION_GRID]: () => middlewareStatic.getLicenseSelectionGridCodes(),
    [ID.SEARCH_CODES]: () => middlewareStatic.getSearchCodes(),
    [ID.ARTICLE]: () => middlewareStatic.getArticleCodes(),
    [ID.ARTICLE_AFFILIATION_EDITOR]: () => middlewareStatic.getArticleAffiliationsEditorCodes(),
    [ID.ARTICLE_AFFILIATION_HELP_WIDGET]: () => middlewareStatic.getArticleAffiliationsHelpWidgetCodes(),
    [ID.INSTITUTION_PICKER]: () => middlewareStatic.getInstitutionPickerCodes(),
    [ID.SIMPLE_PANEL]: () => middlewareStatic.getSimplePanelCodes(),
    [ID.UPLOAD_LICENSE]: () => middlewareStatic.getUploadLicenseCodes(),
    [ID.COMBOBOX]: () => middlewareStatic.getComboboxCodes(),
    [ID.ADDRESS_DOCTOR]: () => middlewareStatic.getAddressDoctorCodes(),
    [ID.DIALOG]: () => middlewareStatic.getDialogCodes(),
    [ID.SERVICE_NAMES]: () => middlewareStatic.getServiceNames(),
    [ID.DISCOUNT_WIDGET]: () => middlewareStatic.getDiscountWidgetCodes(),
    [ID.LICENSE_SIGNING]: () => middlewareStatic.getLicenseSigningCodes(),
    [ID.SUPPORT_WIDGET]: () => middlewareStatic.getSupportWidgetCodes(),
    [ID.WIDGETS]: () => middlewareStatic.getDashboardWidgetCodes(),
    [ID.EMAIL_ALERTS]: () => middlewareStatic.getEmailAlertsCodes(),
    [ID.LICENSE_SUBMISSION]: () => middlewareStatic.getLicenseSubmission(),
};

const loadedCodes: Record<string, Record<string, string>> = {};

async function loadCodes(ids) {
    const codes = await Promise.all(ids.map(id => storage[id]()));

    const codesObj = {};
    ids.forEach((id: string, index) => {
        loadedCodes[id] = codes[index];
        codesObj[id] = codes[index];
    });

    return codesObj;
}

function l(key, params = {}, defaultValue = `[${key}]`, codes = loadedCodes): string {
    const translation = get(codes, key, defaultValue);

    return !_.isString(translation) ? translation : template(translation, params);
}

async function lAsync(key, params = {}, defaultValue = `[${key}]`) {
    const id = key.split('.')[0];

    if (!loadedCodes[id]) {
        await loadCodes([id]);
    }

    return l(key, params, defaultValue);
}

async function preloadCodes() {
    await loadCodes([ID.ERROR, ID.DIALOG, ID.PANEL_STATUS, ID.BUTTONS]);
}

function withCodes<P>(
    WrappedComponent: React.ComponentType<P>,
    ...idList: Values<typeof ID>[]
): React.ComponentType<Omit<P, 'codes' | 'l'>> {
    class WithCodes extends Component<P> {
        static displayName = `WithCodes(${getDisplayName(WrappedComponent)})`;

        constructor(props, context) {
            super(props, context);

            // eslint-disable-next-line react/state-in-constructor
            this.state = {
                exceptionError: null,
                isLoading: true,
            };
        }

        async componentDidMount() {
            try {
                // @ts-ignore
                const validIdList = idList.filter(id => !!storage[id]);
                await loadCodes(validIdList);
            } catch (error) {
                this.setState({
                    exceptionError: error,
                });
            } finally {
                this.setState({ isLoading: false });
            }
        }

        render() {
            // @ts-ignore
            const { exceptionError, isLoading } = this.state;

            if (isLoading || exceptionError) {
                // @ts-ignore
                return <Layout error={exceptionError} isLoading={isLoading} />;
            }

            return <WrappedComponent {...this.props} codes={loadedCodes} l={l} />;
        }
    }

    return skipUnmountedSetStateWarning(WithCodes);
}

function withCodes2(
    ...idList: Values<typeof ID>[]
): <P>(Component: React.ComponentType<P>) => React.ComponentType<Omit<P, 'codes' | 'l'>> {
    return function Codes2(WrappedComponent) {
        return withCodes(WrappedComponent, ...idList);
    };
}

export { ID, storage, withCodes, withCodes2, preloadCodes, l, lAsync, loadCodes };
