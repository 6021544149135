class TempStorage {
    value = undefined;

    get(): any {
        // eslint-disable-next-line prefer-destructuring
        const value = this.value;

        this.value = undefined;

        return value;
    }

    set(value: any) {
        this.value = value;
    }
}

export default new TempStorage();
