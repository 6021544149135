import * as Utils from 'app/blocks/common/utils';
import routes from 'app/pages/routes.json';
import { navigate, reload } from 'app/route/history';

export default {
    getURL(pageName: string, props?: Record<string, any>): string {
        return Utils.template(routes[pageName], { props });
    },
    navigate(pageName: string, props?: Record<string, any>) {
        navigate(this.getURL(pageName, props));
    },
    navigateToUrl(url: string, params?: { replace: boolean }, paramsForNextRoute?: Record<string, any>) {
        setTimeout(() => navigate(url, params, paramsForNextRoute));
    },
    navigateToDashboard() {
        setTimeout(() => navigate(routes.dashboard));
    },
    toLogin({ email = '', replace = false } = {}) {
        return navigate(`/login${email ? `?email=${email}` : ''}`, { replace });
    },
    home(replace: boolean = false) {
        navigate('', { replace });
    },
    reload() {
        reload();
    },
    toArticlePage(articleId: string) {
        navigate(`/article/${articleId}`);
    },
};
