import React from 'react';
import ReactDOM from 'react-dom';
import Menu from 'app/blocks/menu/menu';

// eslint-disable-next-line import/prefer-default-export
export function renderMenu() {
    const menu = document.getElementById('AS__menu');

    return ReactDOM.createPortal(<Menu onDisplay={() => menu.classList.remove('is-loading')} />, menu);
}
