import { getDisplayName } from 'app/blocks/common/utils';

export default function skipUnmountedSetStateWarning(ReactComponentClass) {
    return class WithSkipUnmountedSetStateWarning extends ReactComponentClass {
        static displayName = `WithSkipUnmountedSetStateWarning(${getDisplayName(ReactComponentClass)})`;

        isUnmounted = false;

        componentWillUnmount() {
            this.isUnmounted = true;
        }

        setState(...args) {
            if (!this.isUnmounted) {
                super.setState(...args);
            }
        }
    };
}
