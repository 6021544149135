const labels = {
    'advanced-search-term': 'advanced-search-term',
    filters: 'filters',
    queryText: 'term',
    totalResult: 'result-count',
};
export const mapSearchToAnalyticSearchData = (data = {}) => {
    const result = {};

    for (const [key, value] of Object.entries(data)) {
        const label = labels[key];

        if (label && value) {
            result[label] = value;
        }
    }

    return result;
};
