import cn from 'classnames';
import React from 'react';
import './buttons.scss';

function withProcessing(Component) {
    return React.forwardRef(function ProcessingButton(
        { isProcessing, processingLabel = 'Processing...', ...props },
        ref,
    ) {
        if (isProcessing) {
            return (
                <Component {...props} ref={ref} disabled>
                    {processingLabel}
                </Component>
            );
        }

        return <Component {...props} ref={ref} />;
    });
}

const AbstractButton = withProcessing(
    React.forwardRef(function AbstractButton(
        { className, isBlock = false, isLinkTag = false, type = 'button', ...props },
        ref,
    ) {
        if (isLinkTag) {
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            return <a {...props} ref={ref} className={cn('btn', className, { 'btn--block': isBlock })} />;
        }

        // eslint-disable-next-line react/button-has-type
        return <button {...props} ref={ref} className={cn('btn', className, { 'btn--block': isBlock })} type={type} />;
    }),
);

const Button = React.forwardRef(function Button({ className, ...props }, ref) {
    return <AbstractButton {...props} ref={ref} className={cn('btn-white', className)} />;
});

const PrimaryButton = React.forwardRef(function PrimaryButton({ className, isDanger, ...props }, ref) {
    return (
        <AbstractButton {...props} ref={ref} className={cn('btn-blue', { 'btn-blue--danger': isDanger }, className)} />
    );
});

const LinkButton = React.forwardRef(function LinkButton({ className, isBlack, isDanger, isUnderline, ...props }, ref) {
    return (
        <AbstractButton
            {...props}
            ref={ref}
            className={cn(
                'btn-link',
                {
                    'btn-link--black': isBlack,
                    'btn-link--danger': isDanger,
                    'text-underline': isUnderline,
                },
                className,
            )}
        />
    );
});

export { AbstractButton, Button, LinkButton, PrimaryButton };
