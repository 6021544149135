const PageEnum = {
    ADVANCED_SEARCH: 'Advanced Search',
    AFFILIATIONS: 'Affiliations',
    AFFILIATIONS_SELECT_PRIMARY: 'Select Primary Affiliation',
    AFFILIATIONS_SELECT_WOA_INSTITUTION: 'Select WOA Institution',
    ARTICLE_ASSIGNMENT: 'Article Assignment',
    ARTICLE_PAGE: 'Manage this article',
    ARTICLE_SEARCH_RESULTS: 'Article Search Results',
    CONFIRMATION_LICENSE_SUBMITTED: 'Confirmation: License Submitted',
    CONFIRMATION_ONLINE_OPEN_ORDER_AND_LICENSE_SUBMITTED: 'Confirmation: OnlineOpen Order and License Submitted',
    CONFIRMATION_ONLINE_OPEN_ORDER_SUBMITTED: 'Confirmation: OnlineOpen Order Submitted',
    CONFIRMATION_OPEN_ACCESS_ORDER_SUBMITTED: 'Confirmation: Open Access Order Submitted',
    COPYRIGHT_OWNERSHIP: 'Copyright Ownership',
    COPYRIGHT_OWNERSHIP_SINGLE_AUTHOR: 'Single Author',
    COPYRIGHT_OWNERSHIP_US_GOVERMENT: 'US Government Ownership',
    DASHBOARD: 'My Dashboard',
    EMAIL_VERIFICATION: 'Email Verification',
    FUNDERS: 'Funders',
    GOA_ORDER_INITIATE: 'GOA Order Payment',
    GRANT_DETAILS: 'Grant Details',
    HOME: 'Home',
    JOURNAL_SEARCH_RESULTS: 'Journal Search Results',
    LICENSE_AGREEMENT_TYPES: 'License Agreement Types',
    LICENSE_SUBMISSION_ELECTRONIC: 'Electronic License Submission',
    LICENSE_SUBMISSION_PRINT: 'Print License Submission',
    LOGIN: 'Login',
    ONLINE_OPEN_ORDER_INITIATE: 'OnlineOpen Order: Initiate',
    ONLINE_OPEN_ORDER_REVIEW: 'OnlineOpen Order: Review',
    OPEN_ACCESS: 'Open Access Payment: ',
    OPEN_ACCESS_OPTIONS: 'Open Access Options',
    OPEN_ACCESS_ORDER_INITIATE: 'Open Access Payment: Initiate',
    ORDERS: 'My Orders',
    PROFILE: 'Profile',
    PUB_EDITOR: 'License Verification',
    RECOVER: 'Password Recovery',
    REGISTER: 'Register',
    SEARCH: 'Search Results',
    TERMS_OF_SERVICE: 'Terms Of Service',
    VIEW_ALL_ORDERS: 'Order History',
} as const;

export default PageEnum;
