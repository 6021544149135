// eslint-disable-next-line max-classes-per-file
class ServiceError extends Error {
    code: string;

    url: string;

    refId: string;

    payload?: Object;

    extendedMessage?: string;

    constructor(message, code, url, refId, payload = null, extendedMessage = '') {
        super(code === 'INTERNAL_SERVER_ERROR' ? `${message} url: ${url}` : message);

        this.code = code;
        this.url = url;
        this.refId = refId;
        this.payload = payload;
        this.extendedMessage = extendedMessage;
    }
}

class ValidationError extends Error {
    name: string;

    errors: string[];

    constructor(message, errors) {
        super(message);
        this.name = 'ValidationError';
        this.errors = errors;
    }
}

export { ValidationError, ServiceError };
