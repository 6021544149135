import storage from 'app/blocks/common/storage';

const keys = {
    LOGIN: 'login_cta_region',
    REGISTRATION: 'registration_cta_region',
    PAYMENT_CHECKOUT: 'payment_checkout',
};

const addLoginValue = value => storage.set(keys.LOGIN, value);

const removeLoginValue = () => {
    const value = storage.get(keys.LOGIN);
    storage.remove(keys.LOGIN);
    return value;
};

const addRegistrationValue = value => storage.set(keys.REGISTRATION, value);

const removeRegistrationValue = () => {
    const value = storage.get(keys.REGISTRATION);
    storage.remove(keys.REGISTRATION);
    return value;
};

const addPaymentCheckout = value => storage.set(keys.PAYMENT_CHECKOUT, value);

const removePaymentCheckout = () => {
    const value = storage.get(keys.PAYMENT_CHECKOUT);
    storage.remove(keys.PAYMENT_CHECKOUT);
    return value;
};

export {
    addLoginValue,
    addPaymentCheckout,
    addRegistrationValue,
    removeLoginValue,
    removePaymentCheckout,
    removeRegistrationValue,
};
