import 'core-js/stable';
import 'regenerator-runtime';

if (!('classList' in SVGElement.prototype)) {
    Object.defineProperty(SVGElement.prototype, 'classList', {
        get() {
            return {
                contains: className => this.className.baseVal.split(' ').indexOf(className) !== -1,
            };
        },
    });
}

// suppress 'svg-sprite-loader' errors while checking ('angular' in window)
// see "Cannot read property 'module' of undefined" errors:
// https://app.logrocket.com/p5tsjd/author-services-l7k5a/issue/8668662
if ('angular' in window && !window.angular) {
    delete window.angular;
}

(function (arr) {
    arr.forEach(function (item) {
        if (Object.prototype.hasOwnProperty.call(item, 'append')) {
            return;
        }
        Object.defineProperty(item, 'append', {
            configurable: true,
            enumerable: true,
            writable: true,
            value(...args) {
                const argArr = Array.prototype.slice.call(args);
                const docFrag = document.createDocumentFragment();

                argArr.forEach(function (argItem) {
                    const isNode = argItem instanceof Node;
                    docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                });

                this.appendChild(docFrag);
            },
        });
    });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);
