import React from 'react';
import { useAuth } from 'app/blocks/common/providers/AuthProvider';

export default function withAuth<P>(
    Component: React.ComponentType<P>,
): React.ComponentType<Omit<P, 'user' | 'isAuth'>> {
    return function WithAuth(props: P) {
        return <Component {...props} {...useAuth()} />;
    };
}
