import classNames from 'classnames';
import React from 'react';
import './svg-icon.scss';

const icons = [
    'error',
    'important',
    'previous',
    'next',
    'chevron',
    'calendar',
    'cancel',
    'clear',
    'cancel2',
    'ccBy',
    'ccCc',
    'ccNc',
    'ccNd',
    'ccSa',
    'delete',
    'down',
    'download',
    'edit',
    'info',
    'loading',
    'ok',
    'add',
    'help',
    'search',
    'new',
    'openAccess',
    'orcidLink',
    'hand',
    'articleSearch',
    'thunder',

    'eyeHide',
    'eyeShow',
    'pdf',
    'person',
    'avatarDefault',
    'orcidLogo',

    'stepActive',
    'up',
    'mail',
    'chat',

    'buy',
    'buyOA',
    'sign',
    'licenseCTA',
    'licenseCC',
    'video',
    'share',
    'payOA',
    'cover',
    'clock',
    'schedule',

    'visaLogo',
    'alipayLogo',
    'americanExpressLogo',
    'discoverLogo',
    'masterCardLogo',
    'alipay',
    'alipay2',
    'noteForAlipayIcon',
    'globe',
    'globeOnPalm',
    'waiver',
    'myAccountLink',
    'connectAvator',
    'connectEmail',
    'connectLogo',
    'connectPrimary',
    'orcidLogoIcon',
    'receipt',
] as const;

icons.forEach(key => {
    try {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        require(`./svg-icons/${key}.svg`);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('import error', error);
        throw error;
    }
});

type Props = {
    iconTitle?: string;
    iconDescr?: string;
    className?: string;
    seleniumid?: string;
    style?: Record<string, string>;
    [prop: string]: any;
};

function SvgIcon({
    className,
    iconId,
    iconTitle,
    seleniumid,
    style,
    ...params
}: Props & { iconId: string }): React.ReactElement {
    return (
        <svg
            aria-label={iconTitle}
            className={classNames(`svg-icon svg-icon--${iconId}`, className)}
            data-seleniumid={seleniumid}
            role="img"
            style={style}
        >
            <use {...params} xlinkHref={`#${iconId}`} />
        </svg>
    );
}

// @ts-ignore
const SvgIcons: { [icon in (typeof icons)[number]]: React.ComponentType<Props> } = {};

icons.forEach(key => {
    SvgIcons[key] = props => <SvgIcon {...props} iconId={key} />;
});

export default SvgIcons;
