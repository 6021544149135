/* globals RISKX */
import { getUser } from 'app/blocks/middleware/authentication';

const STORE_DOMAIN = window.location.hostname;
const RISKIFIED_URL = `//beacon.riskified.com?shop=${STORE_DOMAIN}`;

export default async function sendBeacon(page) {
    try {
        const user = getUser();
        if (!user.loggedIn) return;

        if (!document.querySelector(`script[src*="${RISKIFIED_URL}"]`)) {
            await new Promise(res => {
                const script = document.createElement('script');
                script.src = RISKIFIED_URL;

                script.async = true;
                script.onload = res;

                document.body.append(script);
            });
        }

        RISKX.setSid(user.sessionId);
        RISKX.go(page || window.location.pathname + window.location.search + window.location.hash);
    } catch (error) {
        // ignore
    }
}
