import React from 'react';
import breakpoints from './breakpoints.json';

function withResponsive<P>(
    WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, `is${keyof typeof breakpoints}`>> {
    return class Responsive extends React.Component<P> {
        static devices = Object.keys(breakpoints)
            .map(deviceName => {
                const description = Object.entries(breakpoints[deviceName])
                    .map(([breakpoint, value]) => `(${breakpoint}: ${value})`)
                    .join(' and ');
                return { [deviceName]: description };
            })
            .reduce((devices, device) => Object.assign(devices, device), {});

        componentDidMount() {
            this.getTypeOfScreen();
            window.addEventListener('resize', this.getTypeOfScreen);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.getTypeOfScreen);
        }

        getTypeOfScreen = () => {
            const state = Object.keys(Responsive.devices)
                .map(type => ({
                    [`is${type}`]: window.matchMedia(Responsive.devices[type]).matches,
                }))
                .reduce((types, type) => Object.assign(types, type), {});

            this.setState({ ...state });
        };

        render() {
            return <WrappedComponent {...this.props} {...this.state} />;
        }
    };
}

export default withResponsive;
