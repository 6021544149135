import { NavigateFunction } from 'react-router-dom';
import routesJson from 'app/pages/routes.json';
import tempStorage from 'app/route/tempStorage';

let history;

function setHistory(value: NavigateFunction) {
    history = value;
}

function isOnlyMenu(): boolean {
    return !history;
}

function convertToHashUrl(url: string): string {
    return `${routesJson.root}#${url.replace(/^\//, '')}`;
}

function navigate(
    url: string,
    params: { replace: boolean } = { replace: false },
    paramsForNextRoute: Record<string, any> = undefined,
) {
    if (isOnlyMenu()) {
        window.location.href = convertToHashUrl(url);
        return;
    }

    tempStorage.set(paramsForNextRoute);

    history(url[0] !== '/' ? `/${url}` : url, { replace: !!params.replace });
}

function reload() {
    document.location.reload();
}

function isHere(url: string) {
    return window.location.hash.match(`#/?${url.replace(/^\//, '')}`);
}

function goBack() {
    history(-1);
}

export { convertToHashUrl, isHere, navigate, reload, setHistory, goBack, isOnlyMenu };
